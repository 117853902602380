import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import MDInput from "components/MDInput"
import { ImageViewer } from "react-image-viewer-dv"
import FileUpload from "layouts/components/FileUpload/FileUpload"
import { useDispatch } from "react-redux"
import { setAlertMessage } from "redux/globalState"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { ckeditorConfig } from "utilities/config"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { deleteIconStyles } from "utilities/customStyles"
import DeleteIcon from "@mui/icons-material/Delete"

const PaymentSection = ({ register, watch, setValue }) => {
  const gpayImage = watch("paymentdetails.gpay.image")

  const phonepeImage = watch("paymentdetails.phonepe.image")

  const paytmImage = watch("paymentdetails.paytm.image")

  const otherImage = watch("paymentdetails.other.image")

  const bankDetails = watch("paymentdetails.bankdetails")

  const dispatch = useDispatch()

  const handleUploadImage = (key, imageurl) => {
    setValue(key, imageurl)
  }

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Payment Details
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("paymentdetails.enabled")}
                onChange={(e) => {
                  setValue("paymentdetails.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput
              type='text'
              label='Payment Details Title'
              {...register("paymentdetails.title", {})}
              extradata={{ pageurl: watch("basicdetails.pageurl") }}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        {/* GpayImage */}
        <Grid item xs={12}>
          <div className='text-base'>GPay Image</div>
          {gpayImage ? (
            <div className='flex flex-col gap-4 items-start'>
              <div className='relative'>
                <DeleteIcon
                  sx={deleteIconStyles}
                  onClick={() => {
                    setValue("paymentdetails.gpay.image", "")
                    dispatch(setAlertMessage({ type: "success", message: "Image Deleted Successfully" }))
                  }}
                />
                <ImageViewer>
                  <img className='object-cover w-52 h-auto' src={gpayImage} alt={"gpay"} />
                </ImageViewer>
              </div>
            </div>
          ) : (
            <FileUpload
              index={"paymentdetails.gpay.image"}
              handleUploadImage={handleUploadImage}
              extradata={{ pageurl: watch("basicdetails.pageurl") }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Gpay UPI' {...register("paymentdetails.gpay.upi", {})} />
          </FormControl>
        </Grid>

        {/* phonepeImage */}
        <Grid item xs={12}>
          <div className='text-base'>PhonePe Image</div>
          {phonepeImage ? (
            <div className='flex flex-col gap-4 items-start'>
              <div className='relative'>
                <DeleteIcon
                  sx={deleteIconStyles}
                  onClick={() => {
                    setValue("paymentdetails.phonepe.image", "")
                    dispatch(setAlertMessage({ type: "success", message: "Image Deleted Successfully" }))
                  }}
                />
                <ImageViewer>
                  <img className='object-cover w-52 h-auto' src={phonepeImage} alt={"phonepe"} />
                </ImageViewer>
              </div>
            </div>
          ) : (
            <FileUpload
              index={"paymentdetails.phonepe.image"}
              handleUploadImage={handleUploadImage}
              extradata={{ pageurl: watch("basicdetails.pageurl") }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='PhonePe UPI' {...register("paymentdetails.phonepe.upi", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <div className='text-base'>Paytm Image</div>
          {paytmImage ? (
            <div className='flex flex-col gap-4 items-start'>
              <div className='relative'>
                <DeleteIcon
                  sx={deleteIconStyles}
                  onClick={() => {
                    setValue("paymentdetails.paytm.image", "")
                    dispatch(setAlertMessage({ type: "success", message: "Image Deleted Successfully" }))
                  }}
                />
                <ImageViewer>
                  <img className='object-cover w-52 h-auto' src={paytmImage} alt={"paytm"} />
                </ImageViewer>
              </div>
            </div>
          ) : (
            <FileUpload
              index={"paymentdetails.paytm.image"}
              handleUploadImage={handleUploadImage}
              extradata={{ pageurl: watch("basicdetails.pageurl") }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Paytm UPI' {...register("paymentdetails.paytm.upi", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <div className='text-base'>Other Image</div>
          {otherImage ? (
            <div className='flex flex-col gap-4 items-start'>
              <div className='relative'>
                <DeleteIcon
                  sx={deleteIconStyles}
                  onClick={() => {
                    setValue("paymentdetails.other.image", "")
                    dispatch(setAlertMessage({ type: "success", message: "Image Deleted Successfully" }))
                  }}
                />
                <ImageViewer>
                  <img className='object-cover w-52 h-auto' src={otherImage} alt={"other"} />
                </ImageViewer>
              </div>
            </div>
          ) : (
            <FileUpload
              index={"paymentdetails.other.image"}
              handleUploadImage={handleUploadImage}
              extradata={{ pageurl: watch("basicdetails.pageurl") }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Other UPI' {...register("paymentdetails.other.upi", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <div className='text-base font-medium'>Bank Details</div>
          <CKEditor
            editor={ClassicEditor}
            data={bankDetails}
            config={ckeditorConfig}
            onChange={(event, editor) => {
              setValue("paymentdetails.bankdetails", editor.getData())
            }}
          />
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default PaymentSection
