import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Divider } from '@mui/material';


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    draggable: true,
    adaptiveHeight: true,
    focusOnSelect: true,
};

const TestimonialSection = ({ testimonies }) => {

    return (
        <MDBox sx={{ marginBottom: "20px" }}>

            <MDTypography variant="h2" textAlign="center">
                TESTIMONIES
            </MDTypography>

            <Divider />

            <Slider {...settings}>
                {testimonies.map((testimonie, index) => {
                    return <MDBox key={index}>
                        <MDTypography variant="h3" textAlign="center">{testimonie.name}</MDTypography>
                        {/* <MDTypography variant="h5" textAlign="center" sx={{ padding: "0px 20px 10px 20px" }}>{testimonie.comment}</MDTypography> */}
                        <img alt={testimonie.name} className='object-contain p-1 m-4 w-full h-[600px]' src={testimonie.image.src} />

                    </MDBox>
                })}
            </Slider>
        </MDBox>
    )
}

export default TestimonialSection