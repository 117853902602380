import React from "react"
import { BrowserRouter } from "react-router-dom"
import App from "App"

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context"
import { Provider } from "react-redux"
import { store } from "redux/store"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { createRoot } from "react-dom/client"

const container = document.getElementById("app")
const root = createRoot(container)

export const queryClient = new QueryClient()

const isDev = process.env.NODE_ENV === "development"

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <ToastContainer autoClose={4000} />
        <QueryClientProvider client={queryClient}>
          <App />
          {isDev && <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />}
        </QueryClientProvider>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
)
