import PropTypes from "prop-types";

// react-router-dom components
import { NavLink } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function DefaultNavbarLink({ icon, name, route, light, className }) {
  return (
    <MDBox
      component={NavLink}
      to={route}
      // mx={1}
      p={1}
      display="flex"
      alignItems="center"
      className={`opacity-100  ${className} nav-link`}
      sx={{
        cursor: "pointer", userSelect: "none",
        color: className === "redButton" ? "#fff" : "inherit",
        backgroundColor: className === "mobile-link" ? "#ababab" : "inherit",
        margin: "5px",
        "&:hover": {
          backgroundColor: className === "mobile-link" ? "#292929" : "inherit",
        }
      }}
    >
      {/* <Icon
        sx={{
          color: ({ palette: { white, secondary } }) => (light ? white.main : secondary.main),
          verticalAlign: "middle",
        }}
      >
        {icon}
      </Icon> */}
      <MDTypography
        variant="button"
        fontWeight="regular"
        color={light ? "white" : "dark"}
        textTransform="capitalize"
        sx={{ width: "100%", lineHeight: 1, fontSize: "1rem" }}
      >
        {name}
      </MDTypography>
    </MDBox>
  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  light: PropTypes.bool,
};

export default DefaultNavbarLink;
