import { baseUrlServer } from "services/baseUrl"

export const socialIcons = [
  {
    iconurl: `${baseUrlServer}/images/facebook.png`,
    url: "",
    description: "",
    label: "Facebook",
  },
  {
    iconurl: `${baseUrlServer}/images/instagram.png`,
    url: "",
    description: "",
    label: "Instagram",
  },
  {
    iconurl: `${baseUrlServer}/images/whatsapp.png`,
    url: "",
    description: "",
    label: "WhatsApp",
  },
  {
    iconurl: `${baseUrlServer}/images/youtube.png`,
    url: "",
    description: "",
    label: "YouTube",
  },
  {
    iconurl: `${baseUrlServer}/images/tik_tok.png`,
    url: "",
    description: "",
    label: "TikTok",
  },
  {
    iconurl: `${baseUrlServer}/images/linkedin.png`,
    url: "",
    description: "",
    label: "LinkedIn",
  },
  {
    iconurl: `${baseUrlServer}/images/twitter.png`,
    url: "",
    description: "",
    label: "Twitter",
  },
  {
    iconurl: `${baseUrlServer}/images/messenger.png`,
    url: "",
    description: "",
    label: "Messenger",
  },
  {
    iconurl: `${baseUrlServer}/images/telegram.png`,
    url: "",
    description: "",
    label: "Telegram",
  },
  {
    iconurl: `${baseUrlServer}/images/snapchat.png`,
    url: "",
    description: "",
    label: "Snapchat",
  },
  {
    iconurl: `${baseUrlServer}/images/pinterest.png`,
    url: "",
    description: "",
    label: "Pinterest",
  },
  {
    iconurl: `${baseUrlServer}/images/reddit.png`,
    url: "",
    description: "",
    label: "Reddit",
  },
  {
    iconurl: `${baseUrlServer}/images/spotify.png`,
    url: "",
    description: "",
    label: "Spotify",
  },
  {
    iconurl: `${baseUrlServer}/images/skype.png`,
    url: "",
    description: "",
    label: "Skype",
  },
  {
    iconurl: `${baseUrlServer}/images/tumblr.png`,
    url: "",
    description: "",
    label: "Tumblr",
  },
  {
    iconurl: `${baseUrlServer}/images/behance.png`,
    url: "",
    description: "",
    label: "Behance",
  },
  {
    iconurl: `${baseUrlServer}/images/dribble.png`,
    url: "",
    description: "",
    label: "Dribble",
  },
  {
    iconurl: `${baseUrlServer}/images/twitch.png`,
    url: "",
    description: "",
    label: "Twitch",
  },
  {
    iconurl: `${baseUrlServer}/images/vimeo.png`,
    url: "",
    description: "",
    label: "Vimeo",
  },
  {
    iconurl: `${baseUrlServer}/images/flickr.png`,
    url: "",
    description: "",
    label: "Flickr",
  },
  {
    iconurl: `${baseUrlServer}/images/google.png`,
    url: "",
    description: "",
    label: "Google",
  },
  {
    iconurl: `${baseUrlServer}/images/sound_cloud.png`,
    url: "",
    description: "",
    label: "SoundCloud",
  },
  {
    iconurl: `${baseUrlServer}/images/tinder.png`,
    url: "",
    description: "",
    label: "Tinder",
  },
  {
    iconurl: `${baseUrlServer}/images/line.png`,
    url: "",
    description: "",
    label: "LINE",
  },
  {
    iconurl: `${baseUrlServer}/images/wechat.png`,
    url: "",
    description: "",
    label: "WeChat",
  },
  {
    iconurl: `${baseUrlServer}/images/justdial.png`,
    url: "",
    description: "",
    label: "Justdial",
  },
  {
    iconurl: `${baseUrlServer}/images/website.png`,
    url: "",
    description: "",
    label: "Website",
  },
  {
    iconurl: `${baseUrlServer}/images/others.png`,
    url: "",
    description: "",
    label: "Others",
  },
]
