import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import React, { useState } from "react"
import { setAlertMessage } from "redux/globalState"
import { useDispatch } from "react-redux"
import Loader from "../Loader/Loader"
import { useImgMutate } from "hooks/useImgMutate"

const FileUpload = ({ index, handleUploadImage, extradata }) => {
  const dispatch = useDispatch()
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = React.useRef()

  const onImageUploadSuccess = (data) => {
    if (data?.imgurl) {
      dispatch(setAlertMessage({ type: "success", message: data?.message }))
      handleUploadImage(index, data?.imgurl)
    } else {
      dispatch(setAlertMessage({ type: "error", message: data?.message }))
      return
    }
  }

  const { mutate: ImageUpload, isLoading: isImgUploading } = useImgMutate(
    "ImageUpload",
    `/api-upload-image-aws.php`,
    onImageUploadSuccess,
    () => {}
  )

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setIsDragging(false)
    const file = e.dataTransfer.files[0]
    uploadFile(file)
  }

  const openFileDialog = () => {
    fileInputRef.current.click()
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0]
    console.log("handleFileInputChange  file-->", file)
    uploadFile(file)
  }

  const uploadFile = (file) => {
    if (file) {
      if (file.size > 5000000) {
        setAlertMessage({
          type: "error",
          message: "File size should be less than 5MB",
        })
        return
      }

      const isNotImage = file.type.split("/")[0] !== "image"

      if (isNotImage) {
        setAlertMessage({
          type: "error",
          message: "Please select an Image",
        })
        return
      }

      ImageUpload({
        image: file,
        pageurl: extradata.pageurl,
      })
    } else {
      setAlertMessage({
        type: "error",
        message: "Please select an Image",
      })
      // Handle case where no file is selected
    }
  }

  return (
    <div
      className={`w-full h-24 border-dashed border-2 hover:cursor-pointer ${
        isDragging ? "border-blue-400" : "border-green-400"
      } ${isDragging ? "bg-green-100" : ""} flex justify-center items-center mt-2`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={openFileDialog}
    >
      <div className=' p-4'>
        {isImgUploading ? (
          <Loader />
        ) : (
          <div className='hover:animate-pulse flex flex-col justify-center items-center'>
            <CloudUploadIcon fontSize='medium' />
            <span className='text-sm'>Upload IMG</span>
          </div>
        )}
        <input
          ref={fileInputRef}
          type='file'
          disabled={isImgUploading}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
        />
      </div>
    </div>
  )
}

export default FileUpload
