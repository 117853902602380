import React, { useEffect, useState } from "react"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"

import Card from "@mui/material/Card"
import { useFetch } from "hooks/useFetch"
import MDTypography from "components/MDTypography"
import DataTable from "examples/Tables/DataTable"
import { Link, useNavigate } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"
import MDButton from "components/MDButton"
// import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported"
import { useWindowSize } from "react-use"

const mobileSize = 870

const Columns = ({ content }) => {
  return (
    <MDBox
      display='flex'
      alignItems='center'
      lineHeight={1}
      sx={{
        wordBreak: "break-word",
        hyphens: "auto",
        "-moz-hyphens": "auto",
        "-ms-hyphens": "auto",
        "-webkit-hyphens": "auto",
        overflowWrap: "break-word",
        width: "max-content",
        maxWidth: "500px",
      }}
    >
      {/* <MDBox lineHeight={1}> */}
      <MDTypography display='block' variant='button' fontWeight='medium'>
        {content || "-"}
      </MDTypography>
      {/* </MDBox> */}
    </MDBox>
  )
}

// const ImgColumns = ({ content }) => {
//   return (
//     <MDBox display='flex' alignItems='center' lineHeight={1}>
//       <MDBox lineHeight={1}>
//         {content ? (
//           <img height={100} width={100} className='object-contain' src={content} alt='logo-img' />
//         ) : (
//           <ImageNotSupportedIcon />
//         )}
//       </MDBox>
//     </MDBox>
//   )
// }

const Pages = () => {
  const [rows, setRows] = useState([])
  const [filterValue, setFilterValue] = useState(null)
  const [search, setSearch] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [filterStatus, setFilterStatus] = useState("")

  const { width } = useWindowSize()

  const navigate = useNavigate()

  const onSuccess = (response) => {
    console.log("view all Pages response", response)
  }

  const onError = (error) => {
    console.error("view all Pages error", error)
    if (error.status === 401) {
      navigate("/sign-in")
    }
  }

  const handleFilterClick = () => {
    setSearchValue(search)
    setFilterStatus(filterValue?.value)
  }

  const fetchKey = `viewAllPages-search->${searchValue}_filter->${filterStatus}`

  const fetchUrl = `/get-pages.php?page=1${searchValue ? `&search=${searchValue}` : ""}${
    filterStatus ? `&status=${filterStatus}` : ""
  }`

  const { isLoading, data, status } = useFetch(fetchKey, fetchUrl, onSuccess, onError)

  const Actions = ({ pageurl }) => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Link to={`${pageurl}`}>
          <MDButton size={width <= mobileSize ? "small" : "medium"} variant='outlined' color='primary'>
            More
          </MDButton>
        </Link>

        <Link to={`/new-page/${pageurl}`}>
          <MDButton
            sx={{
              marginLeft: "10px",
            }}
            size={width <= mobileSize ? "small" : "medium"}
            variant='outlined'
            color='primary'
          >
            Edit
          </MDButton>
        </Link>
      </Box>
    )
  }

  useEffect(() => {
    data &&
      status === "success" &&
      setRows(
        data?.links?.map((link, index) => {
          return {
            serialno: <Columns content={index + 1} />,
            // logoimg: <ImgColumns content={link.bizdetails?.basicdetails?.logoimg} />,
            pageurl: <Columns content={link.pageurl} />,
            pagetitle: (
              <Columns
                content={
                  <>
                    <MDBox
                      fontWeight='medium'
                      lineHeight={1}
                      sx={{
                        width: "100%",
                      }}
                    >
                      {link.pagetitle}
                    </MDBox>
                    {width <= mobileSize && (
                      <MDBox
                        fontWeight='normal'
                        fontSize='small'
                        sx={{
                          width: "100%",
                        }}
                      >
                        {link.pageurl}
                      </MDBox>
                    )}
                  </>
                }
              />
            ),
            views: <Columns content={link.views} />,
            actions: <Actions pageurl={link.pageurl} />,
          }
        })
      )

    return () => {
      setRows([])
    }
  }, [data, status, width])

  const [columns, setColumns] = useState([
    { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: true, width: "5%" },
    { Header: "Page URL", accessor: "pageurl", align: "left", id: 3, show: true, width: "30%" },
    {
      Header: "Page Title",
      accessor: "pagetitle",
      align: "left",
      id: 4,
      show: true,
      width: "40%",
    },
    { Header: "Views", accessor: "views", align: "center", id: 5, show: true, width: "5%" },
    { Header: "Actions", accessor: "actions", align: "center", id: 6, show: true, width: "25%" },
  ])

  useEffect(() => {
    if (width <= mobileSize) {
      setColumns([
        { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: false, width: "5%" },
        { Header: "Page URL", accessor: "pageurl", align: "left", id: 3, show: false },
        { Header: "Page Title", accessor: "pagetitle", align: "left", id: 4, show: true, width: "75%" },
        { Header: "Views", accessor: "views", align: "center", id: 5, show: true, width: "5%" },
        { Header: "Actions", accessor: "actions", align: "center", id: 6, show: true, width: "25%" },
      ])
    } else {
      setColumns([
        { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: true, width: "5%" },
        { Header: "Page URL", accessor: "pageurl", align: "left", id: 3, show: true, width: "35%" },
        { Header: "Page Title", accessor: "pagetitle", align: "left", id: 4, show: true, width: "45%" },
        { Header: "Views", accessor: "views", align: "center", id: 5, show: true, width: "5%" },
        { Header: "Actions", accessor: "actions", align: "center", id: 6, show: true, width: "15%" },
      ])
    }
  }, [width])

  return (
    <>
      <Card>
        <MDBox mx={2} mt={-3} py={2} px={2} bgColor='inherit' borderRadius='lg' coloredShadow='dark'>
          <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <MDTypography variant='h6' color='dark'>
              All Pages Information <span className='inline-block'>( Total Pages: {data?.total} )</span>
            </MDTypography>

            <Link to='/new-page'>
              <MDButton variant='gradient' color='primary'>
                Create New Page
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>

        <MDBox pt={3}>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={true}
              noEndBorder
              canSearch={true}
              search={search}
              setSearch={setSearch}
              handleFilterClick={handleFilterClick}
              filter={{
                canFilter: true,
                filterOptions: [
                  { value: "", label: "All" },
                  { value: 1, label: "Inactive" },
                  { value: 2, label: "Active" },
                  { value: 3, label: "Demo" },
                ],
                filterValue,
                setFilterValue,
              }}
            />
          )}
        </MDBox>
      </Card>
    </>
  )
}

export default Pages
