import React, { useCallback, useEffect } from "react"
import MDBox from "components/MDBox"

import { set, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import BasicDetailsSection from "./Basic/BasicDetails"

import { setAlertMessage } from "redux/globalState"
import { useDispatch } from "react-redux"
import { useMutate } from "hooks/useMutate"
import { queryClient } from "index"
import { useNavigate, useParams } from "react-router-dom"
import PreloadFonts from "./PreloadFonts"
import { schema } from "./schema"
import PageSectionWrap from "./PageSectionWrap"
import { bizDetails as initalBizDetails } from "./bizdetails.js"
import { Typography } from "@mui/material"
import AdditionalDetailsSection from "./Additional/AdditionalDetails"
import { useFetch } from "hooks/useFetch"

const initialDataFields = {
  ...initalBizDetails,
}

export const NewPageContext = React.createContext()

const NewPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //* Getting Page URL
  const { url } = useParams()

  const { data: pageConfigRes } = useFetch(
    `page-config-${url || "default"}`,
    `/get-config.php${url ? `?pageurl=${url}` : ""}`,
    () => {},
    () => {},
    true,
    (res) => res?.config
  )

  const pageConfig = pageConfigRes?.configs ? JSON.parse(pageConfigRes?.configs) : {}

  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    setError,
    watch,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialDataFields,
  })
  // console.log("NewPage  watch-->", watch())

  const [editablePage, setEditablePage] = React.useState(false)
  const [currentPageId, setCurrentPageId] = React.useState("")

  const handleReset = useCallback(
    () => () => {
      reset()
    },
    [reset]
  )

  const { data: pageDetailsRes } = useFetch(
    ["viewpage", url],
    `/get-page.php?pageurl=${url}`,
    () => {},
    (error) => {
      dispatch(setAlertMessage({ type: "error", message: error?.message }))
      navigate("/pages")
      reset(initialDataFields)
    },
    url !== undefined
  )

  useEffect(() => {
    if (pageDetailsRes?.page) {
      const bizDetails = JSON.parse(pageDetailsRes?.page?.bizdetails)
      setEditablePage(true)
      reset(bizDetails)

      if (bizDetails?.locationdetails === undefined) {
        setValue("locationdetails", {
          ...initialDataFields.locationdetails,
        })
      }
      setCurrentPageId(pageDetailsRes?.page.id)
    }
  }, [pageDetailsRes, reset, setValue])

  const onSuccess = (response) => {
    console.log("submittions Response", response)
    dispatch(setAlertMessage({ type: response.status ? "success" : "error", message: response.message }))
    response.status && handleReset()
    response.status && navigate(response.pageurl ? `/pages/${response.pageurl}` : "/pages")
    queryClient.invalidateQueries(["cart"])
  }

  const onError = (error) => {
    console.log("submittions Response", error)
    dispatch(setAlertMessage({ type: "error", message: error?.message }))
  }

  const { mutate, isLoading: isMutating } = useMutate("create-page", "/create-page.php", onSuccess, onError)

  const submitForm = (data) => {
    console.log("submitForm  data-->", data)
    let dataToSend = {
      plan: 1,
      pageid: currentPageId,
      pageurl: data.basicdetails.pageurl,
      pagetitle: data.basicdetails.pagetitle,
      bizdetails: JSON.stringify(data, null, 4),
    }

    mutate(dataToSend)
  }

  return (
    <NewPageContext.Provider value={{ isMutating }}>
      <form onSubmit={handleSubmit(submitForm)}>
        <MDBox width='100%'>
          <PreloadFonts></PreloadFonts>

          <PageSectionWrap
            initialOpen={true}
            header={
              <>
                <Typography fontWeight={600} variant='h4'>
                  Basic Details
                </Typography>
              </>
            }
          >
            <BasicDetailsSection
              watch={watch}
              control={control}
              setValue={setValue}
              register={register}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              editablePage={editablePage}
              config={pageConfig}
              url={url}
              setFocus={setFocus}
            />
          </PageSectionWrap>

          {pageConfig.bizcard && url ? (
            <PageSectionWrap
              header={
                <>
                  <Typography fontWeight={600} variant='h4'>
                    Additional Details
                  </Typography>
                </>
              }
            >
              <AdditionalDetailsSection
                control={control}
                register={register}
                watch={watch}
                errors={errors}
                setValue={setValue}
                editablePage={editablePage}
                config={pageConfig}
              />
            </PageSectionWrap>
          ) : null}
        </MDBox>
      </form>
    </NewPageContext.Provider>
  )
}

export default NewPage
