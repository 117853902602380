import { useMemo, useEffect } from "react"

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// react-table components
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table"

// @mui material components
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Icon from "@mui/material/Icon"
import Autocomplete from "@mui/material/Autocomplete"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDInput from "components/MDInput"
import MDPagination from "components/MDPagination"

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell"
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell"
import { customSelectStyles } from "utilities/customStyles"
import Select from "react-select"
import { IconButton, Paper } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import MDButton from "components/MDButton"

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  search,
  setSearch,
  filter,
  handleFilterClick,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 100
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"]
  const columns = useMemo(() => table.columns, [table])
  const data = useMemo(() => table.rows, [table])

  const { filterValue, setFilterValue } = filter

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance

  // Set the hidden columns
  useEffect(() => {
    setHiddenColumns(columns.filter((column) => column.show === false).map((column) => column.id))
  }, [columns, setHiddenColumns])

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue, setPageSize])

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value)

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination item key={option} onClick={() => gotoPage(Number(option))} active={pageIndex === option}>
      {option + 1}
    </MDPagination>
  ))

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value))

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1)

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1))

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce"
    } else if (isSorted) {
      sortedValue = "none"
    } else {
      sortedValue = false
    }

    return sortedValue
  }

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1

  // Setting the entries ending point
  let entriesEnd

  if (pageIndex === 0) {
    entriesEnd = pageSize
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length
  } else {
    entriesEnd = pageSize * (pageIndex + 1)
  }

  return (
    <>
      {entriesPerPage || canSearch ? (
        <MDBox display='flex' justifyContent='end' alignItems='center' p={1} gap={1} mr={2}>
          {entriesPerPage && (
            <MDBox display='flex' alignItems='center'>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10))
                }}
                size='small'
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant='caption' color='secondary'>
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}

          {filter?.canFilter && (
            <Select
              placeholder='Status'
              options={filter?.filterOptions}
              value={filterValue}
              onChange={(value) => setFilterValue(value)}
              styles={customSelectStyles}
            />
          )}

          {canSearch && (
            <Paper component='form' sx={{ display: "flex", alignItems: "center", boxShadow: "none", gap: "8px" }}>
              <MDInput
                placeholder='Search...'
                // inputProps={{
                //   readOnly: true,
                // }}
                value={search}
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value)
                }}
              />

              {/* <IconButton type='button' sx={{ p: "10px" }} aria-label='search'>
                <SearchIcon />
              </IconButton> */}

              <MDButton color='primary' onClick={handleFilterClick}>
                Filter
              </MDButton>
            </Paper>
          )}
        </MDBox>
      ) : null}
      <TableContainer sx={{ boxShadow: "none" }}>
        {entriesPerPage ? (
          <MDBox display='flex' justifyContent='space-between' alignItems='center' p={3}>
            {entriesPerPage && (
              <MDBox display='flex' alignItems='center'>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10))
                  }}
                  size='small'
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant='caption' color='secondary'>
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        ) : null}
        <Table {...getTableProps()}>
          <MDBox component='thead'>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

        <MDBox
          display='flex'
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent='space-between'
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant='button' color='secondary' fontWeight='regular'>
                Showing {entriesStart} to {entriesEnd} of {rows.length} entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width='5rem' mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      </TableContainer>
    </>
  )
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
}

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
}

export default DataTable
