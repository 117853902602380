import {
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import PageSectionWrap from "../PageSectionWrap"
import MDInput from "components/MDInput"
import DraggableList from "layouts/components/DraggableList/DraggableList"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import { useDispatch } from "react-redux"
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import { setAlertMessage } from "redux/globalState"
import { useFieldArray } from "react-hook-form"
import MDButton from "components/MDButton"
import { baseUrlServer } from "services/baseUrl"
import { customSelectStyles } from "utilities/customStyles"
import { socialIcons } from "constant/data"
import Select from "react-select"
import MDBox from "components/MDBox"

const SocialIconContent = ({ register, item, index }) => {
  return (
    <Card className='mt-2'>
      <CardContent
        sx={{
          padding: "0.5rem",
        }}
        className='flex items-center flex-wrap gap-2 sm:flex-nowrap sm:gap-4 justify-between '
      >
        <DragHandleIcon />
        <div className=''>
          <img src={item.iconurl} alt='icon' className='w-8 h-8 max-w-max' />
          {/* {index === 7 && <span className='text-base'>Others</span>} */}
        </div>
        <FormControl variant='outlined' sx={{ width: "100%" }}>
          <MDInput type='text' label='Url' {...register(`socials.data.${index}.url`, {})} />
        </FormControl>
        <FormControl variant='outlined' sx={{ width: "100%" }}>
          <MDInput type='text' label='Description' {...register(`socials.data.${index}.description`, {})} />
        </FormControl>
      </CardContent>
    </Card>
  )
}

const SocialMediaLinks = ({ register, watch, setValue, control, config }) => {
  const controlledSocials = watch("socials.data")

  const [items, setItems] = React.useState([])
  const [extraIconSelected, setExtraIconSelected] = React.useState(null)

  useEffect(() => {
    const socials = controlledSocials?.map((item, index) => {
      return {
        id: `item-${index}`,
        content: <SocialIconContent register={register} item={item} index={index} />,
        data: item,
      }
    })
    setItems(socials)

    return () => {
      setItems([])
    }
  }, [controlledSocials, register])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return

    const newItems = reorder(items, source.index, destination.index)

    setValue(
      "socials.data",
      newItems.map((item) => item.data)
    )
    setItems(newItems)
  }

  const handleAddExtraIcon = (selected) => {
    setExtraIconSelected(selected)
    const selectedSocialIcon = Object.assign(
      {},
      socialIcons.find((item) => item.iconurl === selected?.value)
    )

    delete selectedSocialIcon?.label

    const newItems = [
      ...items,
      {
        id: `item-${items.length}`,
        content: <SocialIconContent register={register} item={selectedSocialIcon} index={items.length} />,
        data: selectedSocialIcon,
      },
    ]

    setValue(
      "socials.data",
      newItems.map((item) => item.data)
    )
    setItems(newItems)
  }
  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <Typography fontWeight={500} variant='h5'>
          Social Media Links
        </Typography>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("socials.enabled")}
                onChange={(e) => {
                  setValue("socials.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginBottom: "1rem",
          }}
        >
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Socials Section Title' {...register("socials.title", {})} />
          </FormControl>
        </Grid>
      </Grid>

      <Divider
        sx={{
          my: ".5rem",
        }}
      />

      <MDBox
        my={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <MDBox
          sx={{
            width: "300px",
          }}
        >
          <Select
            placeholder='Add Extra Social Icons'
            options={socialIcons.map((item) => ({ value: item.iconurl, label: item.label }))} // socialIcons
            value={extraIconSelected}
            onChange={handleAddExtraIcon}
            styles={customSelectStyles}
            formatOptionLabel={(option) => (
              <div className='flex items-center gap-2'>
                <img src={option.value} alt='icon' className='w-8 h-8 max-w-max' />
                <span>{option.label}</span>
              </div>
            )}
          />
        </MDBox>
      </MDBox>

      <DraggableList items={items} onDragEnd={onDragEnd} />

      <OtherSocials register={register} control={control} config={config} />
    </PageSectionWrap>
  )
}

export default SocialMediaLinks

const OtherSocials = ({ register, control, config }) => {
  const dispatch = useDispatch()

  const {
    append: appendOtherSocial,
    fields: otherSocialFields,
    remove: removeOtherSocial,
  } = useFieldArray({
    control: control,
    name: "socials.othersocials",
    keyName: "id",
    shouldUnregister: false,
  })

  const handleRemoveOtherSocial = (index) => {
    if (otherSocialFields.length === 1) {
      dispatch(setAlertMessage({ type: "error", message: "Atleast one Other Social is required" }))
      return
    }
    removeOtherSocial(index)
  }

  const handleAddOtherSocial = () => {
    if (otherSocialFields.length >= config.othersocials) {
      dispatch(setAlertMessage({ type: "error", message: `Maximum ${config.othersocials} Other Social is allowed` }))
      return
    }
    appendOtherSocial({
      iconurl: `${baseUrlServer}/images/others.png`,
      url: "",
      notes: "",
    })
  }

  return (
    <Grid item xs={12}>
      {otherSocialFields.map((item, index) => (
        <>
          {index > 0 && (
            <Divider
              sx={{
                my: "1rem",
              }}
            />
          )}

          <Card className='mt-2'>
            <CardContent
              sx={{
                padding: "0.5rem",
              }}
              className='flex items-center flex-wrap gap-2 sm:flex-nowrap sm:gap-4 justify-between '
            >
              <img src={item.iconurl} alt='icon' className='w-8 h-8 max-w-max' title='Other Social Link' />

              {index > 0 && (
                <div className='text-end'>
                  <IconButton
                    color='error'
                    title='Remove Other Social'
                    type='button'
                    onClick={() => handleRemoveOtherSocial(index)}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              )}

              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput type='text' label='Url' {...register(`socials.othersocials.${index}.url`, {})} />
              </FormControl>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput
                  type='text'
                  label='Description'
                  {...register(`socials.othersocials.${index}.description`, {})}
                />
              </FormControl>
            </CardContent>
          </Card>
        </>
      ))}

      <MDButton
        sx={{
          gap: "0.5rem",
          marginTop: "12px",
        }}
        variant='contained'
        color='success'
        size='small'
        onClick={handleAddOtherSocial}
      >
        <AddCircleOutline /> Add Link
      </MDButton>
    </Grid>
  )
}
