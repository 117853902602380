import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, IconButton, Switch, Typography } from "@mui/material"
import { useFieldArray } from "react-hook-form"
import { setAlertMessage } from "redux/globalState"
import { useDispatch } from "react-redux"
import MDInput from "components/MDInput"
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import MDButton from "components/MDButton"

const DocumentSection = ({ control, register, config, watch, setValue }) => {
  const dispatch = useDispatch()

  const {
    append: appendDocuments,
    fields: documentsFields,
    remove: removeDocuments,
  } = useFieldArray({
    control: control,
    name: "documents.data",
    keyName: "id",
    shouldUnregister: false,
  })

  const handleRemoveDocument = (index) => {
    if (documentsFields.length === 1) {
      dispatch(setAlertMessage({ type: "error", message: "At least one Document is required" }))
      return
    }
    removeDocuments(index)
  }

  const handleAddDocument = () => {
    if (documentsFields.length >= config.documents) {
      dispatch(setAlertMessage({ type: "error", message: `Maximum ${config.documents} Documents are only allowed` }))
      return
    }

    appendDocuments({ url: "", description: "" })
  }

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Documents
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("documents.enabled")}
                onChange={(e) => {
                  setValue("documents.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Document Section Title' {...register("documents.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {documentsFields.map((item, index) => (
            <>
              {index > 0 && (
                <Divider
                  sx={{
                    my: "1rem",
                  }}
                />
              )}
              {index > 0 && (
                <div className='text-end'>
                  <IconButton type='button' color='error' onClick={() => handleRemoveDocument(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              )}
              <div key={item.id} className='flex flex-col gap-4'>
                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  <MDInput type='text' label='Document Url' {...register(`documents.data.${index}.url`, {})} />
                </FormControl>
                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  <MDInput
                    type='text'
                    multiline={true}
                    rows={3}
                    label='Document Description'
                    {...register(`documents.data.${index}.description`, {})}
                  />
                </FormControl>
              </div>
            </>
          ))}

          <MDButton
            sx={{
              gap: "0.5rem",
              marginTop: "12px",
            }}
            color='success'
            type='button'
            size='small'
            onClick={handleAddDocument}
          >
            <AddCircleOutline /> Add Document
          </MDButton>
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default DocumentSection
