// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

//Icon
import CloseIcon from '@mui/icons-material/Close';

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { Alert, CircularProgress, Collapse, FormHelperText, IconButton } from "@mui/material";
import { useMutate } from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { setAlertMessage } from "redux/globalState";
import BasicLayout from "layouts/components/BasicLayout";
import { errorFinder } from "utilities/commonFunctions";


const initialFormData = {
  mobile: "",
}

const initialOTPData = {
  otp: "",
}

const initialPasswordData = {
  password: "",
  confirmPassword: ""
}

const mobileSchema = yup
  .object()
  .shape({
    mobile: yup.string().min(10, "Should have atleat 10 Numbers").max(14).required("Mobile Number is Required"),
  })
  .required();

const oTpSchema = yup
  .object()
  .shape({
    otp: yup.string().min(4, "Should have atleat 4 Numbers").max(6, "Should have atmost 6 Numbers").required()
  })
  .required();

const passwordSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(6, "Password Must be minimum 6 digits")
      .max(30)
      .required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();


export const ResetPassword = () => {

  const { register: mobileRegister, handleSubmit: mobileHandleSubmit, formState: { errors: mobileErrors } } = useForm({
    resolver: yupResolver(mobileSchema),
    defaultValues: initialFormData,
    shouldUnregister: false,
  });

  const { register: otpRegister, handleSubmit: otpHandleSubmit, formState: { errors: otpErrors } } = useForm({
    resolver: yupResolver(oTpSchema),
    defaultValues: initialOTPData,
    shouldUnregister: false,
  });

  const { register: passwordRegister, handleSubmit: passwordHandleSubmit, formState: { errors: passwordErrors } } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: initialPasswordData,
    shouldUnregister: false,
  });


  const initialErrorAlertState = { message: "", state: false }
  const [errorAlert, setErrorAlert] = useState(initialErrorAlertState);
  // const [formData, setFormData] = useState(initialFormData)
  const [isMobileVerified, setIsMobileVerified] = useState(false);

  const [OTPVerified, setOTPVerified] = useState(false)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onMobileSuccess = async (response) => {
    console.log('onSuccess  response:', response)
    //handle success
    if (response.status) {
      setErrorAlert({ message: "", state: false })
      dispatch(setAlertMessage({ type: "success", message: response.message }))
      setIsMobileVerified(true)
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }
  const onMobileError = async (error) => {
    console.log('onError  error:', error)
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error)
  }

  const onOtpSuccess = async (response) => {
    console.log('onSuccess  response:', response)
    //handle success
    if (response.status) {
      setErrorAlert({ message: "", state: false })
      dispatch(setAlertMessage({ type: "success", message: response.message }))
      setOTPVerified(true)
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }
  const onOtpError = async (error) => {
    console.log('onError  error:', error)
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error)
  }

  const onPasswordSuccess = async (response) => {
    console.log('onSuccess  response:', response)
    //handle success
    if (response.status) {
      setErrorAlert({ message: "", state: false })
      dispatch(setAlertMessage({ type: "success", message: response.message }))
      navigate("/sign-in")
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }
  const onPasswordError = async (error) => {
    console.log('onError  error:', error)
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error)
  }

  const { mutate: mutateMobile, isLoading: isMobileVerifiedLoading } =
    useMutate("posting-mobile", `/api_forgot_password.php`, onMobileSuccess, onMobileError)

  const { mutate: mutateOTP, isLoading: otpVerificationLoading } =
    useMutate("posting-otp", `/api_forgot_password_otp.php`, onOtpSuccess, onOtpError)

  const { mutate: mutatePassword, isLoading: passwordUpdationLoading } =
    useMutate("posting-otp", `/api_forgot_password_reset.php`, onPasswordSuccess, onPasswordError)


  const handleMobileFormSubmit = (e) => {
    let bodyFormData = new FormData()
    bodyFormData.append("mobile", e.mobile)

    mutateMobile(bodyFormData)
  };

  const handleOTPFormSubmit = (e) => {
    let bodyFormData = new FormData()
    bodyFormData.append("otp", e.otp)

    mutateOTP(bodyFormData)
  };

  const handlePasswordFormSubmit = (e) => {
    let bodyFormData = new FormData()
    bodyFormData.append("password", e.password)

    mutatePassword(bodyFormData)
  };

  return (
    <BasicLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          bgColor="inherit"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="dark" mt={1}>
            Reset Password
          </MDTypography>

          {isMobileVerified && !OTPVerified && <MDTypography display="block" variant="button" color="dark" my={1}>
            You will receive an OTP in 60 seconds
          </MDTypography>}
        </MDBox>

        <MDBox width={"100%"}>
          <Collapse sx={{ marginBottom: "-25px" }} in={errorAlert.state}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlert(initialErrorAlertState);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlert.message}
            </Alert>
          </Collapse>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>

          {
            OTPVerified ?
              <MDBox component="form" role="form" onSubmit={passwordHandleSubmit(handlePasswordFormSubmit)}>
                <MDBox mb={2}>
                  <MDInput
                    {...passwordRegister("password")}
                    type="password" label="Password" fullWidth />
                  {passwordErrors.password && <FormHelperText error id="password-helper-text">{passwordErrors.password?.message}</FormHelperText>}

                  <MDInput
                    sx={{ mt: 2 }}
                    error={errorFinder(passwordErrors.confirmPassword)}
                    {...passwordRegister("confirmPassword")}
                    type="password" label="Confirm Password" fullWidth />
                  {passwordErrors.confirmPassword && <FormHelperText error id="confirmPassword-helper-text">{passwordErrors.confirmPassword?.message}</FormHelperText>}
                </MDBox>

                <MDBox mt={6} mb={1} sx={{ textAlign: "center" }}>
                  <MDButton type="submit" variant="gradient" color="primary">
                    {passwordUpdationLoading ? <CircularProgress color="inherit" /> : "Confirm"}
                  </MDButton>
                </MDBox>
              </MDBox>
              :
              !isMobileVerified ?
                <MDBox component="form" role="form" onSubmit={mobileHandleSubmit(handleMobileFormSubmit)}>
                  <MDBox mb={2}>
                    <MDInput
                      error={errorFinder(mobileErrors.mobile)}
                      {...mobileRegister("mobile")}
                      type="text" label="Mobile" fullWidth />
                    {mobileErrors.mobile && <FormHelperText error id="mobile-helper-text">{mobileErrors.mobile?.message}</FormHelperText>}
                  </MDBox>

                  <MDBox mt={6} mb={1} sx={{ textAlign: "center" }}>
                    <MDButton type="submit" variant="gradient" color="primary">
                      {isMobileVerifiedLoading ? <CircularProgress color="inherit" /> : "Reset"}
                    </MDButton>
                  </MDBox>
                </MDBox>
                :
                <MDBox component="form" role="form" onSubmit={otpHandleSubmit(handleOTPFormSubmit)}>
                  <MDBox mb={2}>
                    <MDInput
                      {...otpRegister("otp")}
                      type="text" label="Enter OTP" fullWidth />
                    {otpErrors.otp && <FormHelperText error id="otp-helper-text">{otpErrors.otp?.message}</FormHelperText>}
                  </MDBox>

                  <MDBox mt={6} mb={1} sx={{ textAlign: "center" }}>
                    <MDButton type="submit" variant="gradient" color="primary" >
                      {otpVerificationLoading ? <CircularProgress color="inherit" /> : "Submit"}
                    </MDButton>
                  </MDBox>
                </MDBox>
          }

          <MDBox mt={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Don't have Account? {" "}
              <MDTypography
                component={Link}
                to="/sign-up"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Register Now
              </MDTypography>
            </MDTypography>

          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}


