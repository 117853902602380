import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'

const MainSection = ({ topic, content, image }) => {
    return (
        <Grid container className='mt-12'>

            <Grid item xs={12} md={6}>
                <MDBox>
                    <MDTypography sx={{ fontSize: "2rem" }} className="text-center">
                        {topic}
                    </MDTypography>

                    <MDTypography sx={{ marginTop: "3rem" }} className="text-justify justify-center">
                        {content}
                    </MDTypography>
                </MDBox>
            </Grid>

            <Grid item xs={12} md={6}>
                <MDBox className="xs:ml-0">
                    <img className='object-cover m-4 w-full h-[600px]' src={image.src} alt={image.alt} />
                </MDBox>
            </Grid>

        </Grid>
    )
}

export default MainSection