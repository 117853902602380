import MainLayout from 'layouts/mainLayout'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { checkIfValid } from 'utilities/commonFunctions'

const ProtectedRouteAuth = () => {
    const accessToken = useSelector((state) => state.globalState.accessToken)
    const username = useSelector((state) => state.globalState.username)
    const isValid = checkIfValid(accessToken, username)


    return (
        isValid ? <MainLayout /> : <Navigate to="sign-in" />
    )
}

export default ProtectedRouteAuth