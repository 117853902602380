// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";

import { Link } from "react-router-dom";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { motion } from "framer-motion"
import { duration } from "moment";

function Footer({ light, position }) {
  const { size } = typography;

  return (
    <MDBox position={position ? position : "absolute"} width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
        >
          <motion.div
            initial={{ y: "20px", opacity: 0 }}
            animate={{ y: "0px", opacity: 1 }}
            transition={{
              opacity: { ease: "easeInOut" },
              duration: .3
            }}
          >
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              color={light ? "white" : "text"}
              fontSize={size.sm}
            >
              <MDTypography color={light ? "white" : "dark"}>
                For Any Questions
              </MDTypography>
              <a href="tel:9043022255">
                <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                  Contact: +91 9043022255
                </MDTypography>
              </a>
            </MDBox>
          </motion.div>

          <MDBox
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            <motion.div
              initial={{ y: "20px" }}
              animate={{ y: "0px" }}
              transition={{
                opacity: { ease: "easeInOut" },
                duration: .5
              }}
            >

              <MDBox pr={2} lineHeight={1}>
                <Link to="/pricing">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={light ? "white" : "dark"}
                  >
                    Pricing
                  </MDTypography>
                </Link>
              </MDBox>
            </motion.div>

            <motion.div
              initial={{ y: "20px", opacity: 0 }}
              animate={{ y: "0px", opacity: 1 }}
              transition={{
                opacity: { ease: "easeInOut" },
                duration: .7
              }}
            >
              <MDBox px={2} lineHeight={1}>
                <Link to="/termsOfService">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={light ? "white" : "dark"}
                  >
                    Terms of Service
                  </MDTypography>
                </Link>
              </MDBox>
            </motion.div>

            <motion.div
              initial={{ y: "20px", opacity: 0 }}
              animate={{ y: "0px", opacity: 1 }}
              transition={{
                opacity: { ease: "easeInOut" },
                duration: .9
              }}
            >
              <MDBox px={2} lineHeight={1}>
                <Link to="/features">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={light ? "white" : "dark"}
                  >
                    Features
                  </MDTypography>
                </Link>
              </MDBox>
            </motion.div>

            <motion.div
              initial={{ y: "20px", opacity: 0 }}
              animate={{ y: "0px", opacity: 1 }}
              transition={{
                opacity: { ease: "easeInOut" },
                duration: 1.1
              }}
            >
              <MDBox px={2} lineHeight={1}>
                <Link to="/contact" >
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={light ? "white" : "dark"}
                  >
                    Contact
                  </MDTypography>
                </Link>
              </MDBox>
            </motion.div>

          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
