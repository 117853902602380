// @mui material components
import Grid from "@mui/material/Grid"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"

// Material Dashboard 2 React example components
import { useSelector } from "react-redux"
import MDTypography from "components/MDTypography"
import { Link } from "react-router-dom"
import Projects from "./components/Projects"
import OrdersOverview from "./components/OrdersOverview"
import { useFetch } from "hooks/useFetch"
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart"
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart"
import reportsBarChartData from "./data/reportsBarChartData"
import reportsLineChartData from "./data/reportsLineChartData"
import { Card, CardContent, Typography } from "@mui/material"
import PieChart from "examples/Charts/PieChart"
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// // Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// // Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData
  const username = useSelector((state) => state.globalState.username)

  const userRole = useSelector((state) => state.globalState.userRole)

  const isSuperAdmin = userRole === "super_admin" ? true : false

  const purchase = useSelector((state) => state.globalState.purchase)

  const isPurchasedProduct1AndNotUsed = purchase
    ? purchase.paymenthistory?.some((item) => item.productid === "1" && item.isused === 0)
    : false

  const { data: dataRes } = useFetch(
    "get-dashboard-data",
    "/get-dashboard.php",
    () => {},
    () => {},
    true
  )

  const usersData = dataRes?.usersData || []
  console.log("Dashboard  usersData-->", usersData)

  const linksData = dataRes?.linksData || []
  console.log("Dashboard  linksData-->", linksData)

  const textCenterPlugin = {
    id: "textCenterPlugin",
    beforeDatasetsDraw: (chart) => {
      const { ctx, data } = chart
      ctx.save()
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"
      ctx.font = "20px Arial"
      ctx.fillStyle = "#000000"
      const total = data.datasets[0].data.reduce((a, b) => a + b, 0)
      const text = `${data.datasets[0].data[0]}/${total}`

      const textX = Math.round(ctx.canvas.clientWidth / 2)
      const textY = Math.round(ctx.canvas.clientHeight / 2)

      ctx.fillText(text, textX, textY + 20)

      // ctx.fillText("", textX, textY + 50)
    },
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4' fontWeight='medium'>
            Welcome {username}
          </Typography>
        </Grid>

        {isSuperAdmin && (
          <>
            <Grid item xs={12} md={6}>
              <PieChart
                height={200}
                title='Users Details'
                chart={{
                  labels: ["Active", "Inactive"],
                  datasets: [
                    {
                      data: [parseInt(usersData?.activeUsers) || 0, parseInt(usersData?.inactiveUsers) || 0],
                      borderWidth: 1,
                      backgroundColor: ["#4caf50", "#f44336"],
                    },
                  ],
                }}
                plugins={[textCenterPlugin]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PieChart
                height={200}
                title='Page Details'
                chart={{
                  labels: ["Active", "Inactive", "Demo"],
                  datasets: [
                    {
                      data: [
                        parseInt(linksData?.activeLinks) || 0,
                        parseInt(linksData?.inactiveLinks) || 0,
                        parseInt(linksData?.demoLinks) || 0,
                      ],
                      borderWidth: 1,
                      backgroundColor: ["#4caf50", "#f44336", "#3f51b5"],
                    },
                  ],
                }}
                plugins={[textCenterPlugin]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>

    //  <MDBox py={3}>
    //     <Grid container spacing={3}>
    //       <Grid item xs={12} md={6} lg={3}>
    //         <MDBox mb={1.5}>
    //           <ComplexStatisticsCard
    //             color="dark"
    //             icon="weekend"
    //             title="Bookings"
    //             count={281}
    //             percentage={{
    //               color: "success",
    //               amount: "+55%",
    //               label: "than lask week",
    //             }}
    //           />
    //         </MDBox>
    //       </Grid>
    //       <Grid item xs={12} md={6} lg={3}>
    //         <MDBox mb={1.5}>
    //           <ComplexStatisticsCard
    //             icon="leaderboard"
    //             title="Today's Users"
    //             count="2,300"
    //             percentage={{
    //               color: "success",
    //               amount: "+3%",
    //               label: "than last month",
    //             }}
    //           />
    //         </MDBox>
    //       </Grid>
    //       <Grid item xs={12} md={6} lg={3}>
    //         <MDBox mb={1.5}>
    //           <ComplexStatisticsCard
    //             color="success"
    //             icon="store"
    //             title="Revenue"
    //             count="34k"
    //             percentage={{
    //               color: "success",
    //               amount: "+1%",
    //               label: "than yesterday",
    //             }}
    //           />
    //         </MDBox>
    //       </Grid>
    //       <Grid item xs={12} md={6} lg={3}>
    //         <MDBox mb={1.5}>
    //           <ComplexStatisticsCard
    //             color="primary"
    //             icon="person_add"
    //             title="Followers"
    //             count="+91"
    //             percentage={{
    //               color: "success",
    //               amount: "",
    //               label: "Just updated",
    //             }}
    //           />
    //         </MDBox>
    //       </Grid>
    //     </Grid>
    //     <MDBox mt={4.5}>
    //       <Grid container spacing={3}>
    //         <Grid item xs={12} md={6} lg={4}>
    //           <MDBox mb={3}>
    //             <ReportsBarChart
    //               color="info"
    //               title="website views"
    //               description="Last Campaign Performance"
    //               date="campaign sent 2 days ago"
    //               chart={reportsBarChartData}
    //             />
    //           </MDBox>
    //         </Grid>
    //         <Grid item xs={12} md={6} lg={4}>
    //           <MDBox mb={3}>
    //             <ReportsLineChart
    //               color="success"
    //               title="daily sales"
    //               description={
    //                 <>
    //                   (<strong>+15%</strong>) increase in today sales.
    //                 </>
    //               }
    //               date="updated 4 min ago"
    //               chart={sales}
    //             />
    //           </MDBox>
    //         </Grid>
    //         <Grid item xs={12} md={6} lg={4}>
    //           <MDBox mb={3}>
    //             <ReportsLineChart
    //               color="dark"
    //               title="completed tasks"
    //               description="Last Campaign Performance"
    //               date="just updated"
    //               chart={tasks}
    //             />
    //           </MDBox>
    //         </Grid>
    //       </Grid>
    //     </MDBox>
    //     <MDBox>
    //       <Grid container spacing={3}>
    //         <Grid item xs={12} md={6} lg={8}>
    //           <Projects />
    //         </Grid>
    //         <Grid item xs={12} md={6} lg={4}>
    //           <OrdersOverview />
    //         </Grid>
    //       </Grid>
    //     </MDBox>
    //   </MDBox>
  )
}

export default Dashboard
