import { Container } from "@mui/material"
import bgImage from "assets/images/bg-desktop.jpg"
import HomePageLayout from "layouts/components/HomePageLayout"
import MainSection from "layouts/reusable/MainSection"
import { aboutMainContent } from "static/homepage/data"

export const About = () => {
  const { topic, content, image } = aboutMainContent
  return (
    <HomePageLayout bgColor={"inherit"} image={bgImage} pageName='About Us' coverHeight='' heroText=''>
      <Container>
        <MainSection topic={topic} content={content} image={image} />
      </Container>
    </HomePageLayout>
  )
}
