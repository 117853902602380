import { Grid, Paper } from '@mui/material'
import React, { useState } from 'react'
import step1 from "assets/images/review-stand-image-1.jpg"
import step2 from "assets/images/review-stand-image-2.jpg"
import step3 from "assets/images/review-stand-image-3.jpg"
import { motion } from "framer-motion"
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const StepsToUseSection = () => {

    const [step1hov, setStep1hov] = useState(false)
    const [step2hov, setStep2hov] = useState(false)
    const [step3hov, setStep3hov] = useState(false)

    return (
        <Grid container my={4} spacing={3}>

            <Grid item xs={12}>
                <MDTypography display="flex" justifyContent="center" fontSize="2.5rem">
                    How Our Review Stand Works?
                </MDTypography>
            </Grid>

            <Grid item xs={12} md={4}>
                <Paper elevation={3}>
                    <MDBox
                        className='grid isolate place-content-center overflow-hidden'
                        onMouseEnter={() => setStep1hov(true)}
                        onMouseLeave={() => setStep1hov(false)}>

                        <MDBox className='z-0 col-start-1 col-end-1 row-start-1 row-end-1 self-center'>
                            <img className={`object-cover ${step1hov && "scale-125 transform-gpu"} duration-500 w-full h-[600px]`} src={step1} alt="step1" />
                        </MDBox>

                        <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: step1hov ? 0 : 100, opacity: step1hov ? 100 : 0, transition: { duration: .5 }, }}
                            className='z-10 text-white backdrop-blur-lg rounded-md text-xl col-start-1 col-end-1 row-start-1 row-end-1 self-center text-center'>
                            <p className='p-5 m-5' style={{fontSize:"1.5rem"}}>When user scans the QR Code or tap the Review Stand, our website with your business details will be opened in customer's mobile.</p>
                        </motion.div>

                    </MDBox>

                </Paper>

                <MDTypography my={3} display="flex" justifyContent="center">
                    Step 1: User Scan or Tap Review Stand
                </MDTypography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper elevation={3}>
                    <MDBox
                        className='grid isolate place-content-center overflow-hidden'
                        onMouseEnter={() => setStep2hov(true)}
                        onMouseLeave={() => setStep2hov(false)}>

                        <MDBox className='z-0 col-start-1 col-end-1 row-start-1 row-end-1 self-center'>
                            <img className={`object-cover ${step2hov && "scale-125 transform-gpu"} duration-500 w-full h-[600px]`} src={step2} alt="step1" />
                        </MDBox>

                        <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: step2hov ? 0 : 100, opacity: step2hov ? 100 : 0, transition: { duration: .5 } }}
                            className=' z-10 text-white backdrop-blur-lg rounded-md text-xl col-start-1 col-end-1 row-start-1 row-end-1 self-center text-center'>
                            <p className='p-5 m-5' style={{fontSize:"1.5rem"}}>We will show user to enter star rating for your business. User can enter star rating and feedback. Optionally name and mobile number also can be entered.</p>
                        </motion.div>

                    </MDBox>
                </Paper>
                <MDTypography my={3} display="flex" justifyContent="center">
                    Step 2: Gives Star Rating for Your Business
                </MDTypography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper elevation={3}>
                    <MDBox
                        className='grid isolate place-content-center overflow-hidden'
                        onMouseEnter={() => setStep3hov(true)}
                        onMouseLeave={() => setStep3hov(false)}>

                        <MDBox className='z-0 col-start-1 col-end-1 row-start-1 row-end-1 self-center'>
                            <img className={`object-cover ${step3hov && "scale-125 transform-gpu"} duration-500 w-full h-[600px]`} src={step3} alt="step1" />
                        </MDBox>

                        <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: step3hov ? 0 : 100, opacity: step3hov ? 100 : 0, transition: { duration: .5 } }}
                        py={5}
                            className='z-10 text-white backdrop-blur-lg rounded-md text-xl col-start-1 col-end-1 row-start-1 row-end-1 self-center text-center'>
                            <p className='p-5 m-5' style={{fontSize:"1.5rem"}}>If user gives negativem rating, we will show that to you. If user gives positive rating, we will redirect user to Google Business to provide rating.</p>
                        </motion.div>

                    </MDBox>
                </Paper>
                <MDTypography my={3} display="flex" justifyContent="center">
                    Step 3: Positive Rating in Google Business
                </MDTypography>
            </Grid>
        </Grid>
    )
}

export default StepsToUseSection