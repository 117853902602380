import { useState, useEffect } from "react"

// react-router components
import { Link } from "react-router-dom"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"

// @mui material components
import Icon from "@mui/material/Icon"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink"
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile"

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints"

// Material Dashboard 2 React context
import { useMaterialUIController } from "context"
import { Fade } from "@mui/material"

function DefaultNavbar({ transparent, light, action }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  const [mobileNavbar, setMobileNavbar] = useState(false)
  const [mobileView, setMobileView] = useState(false)

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode)
  const closeMobileNavbar = () => setMobileNavbar(false)

  const [show, setShow] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false)
      } else {
        // if scroll up show the navbar
        setShow(true)
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar)

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar)
      }
    }
  }, [lastScrollY])

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.md) {
        setMobileView(true)
        setMobileNavbar(false)
      } else {
        setMobileView(false)
        setMobileNavbar(false)
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar)

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar)
  }, [])

  return (
    <>
      <Fade in={show} appear={false}>
        <MDBox className='container'>
          <MDBox
            className='topNavbarWrapper p-3'
            // px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
            width='100%'
            borderRadius='none'
            shadow={"md"}
            color={darkMode ? "light" : "dark"}
            display={"flex"}
            justifyContent='space-between'
            alignItems='center'
            position={show ? "fixed" : "absolute"}
            left={0}
            zIndex={4}
            sx={({
              palette: { transparent: transparentColor, white, background },
              functions: { rgba },
            }) => ({
              backgroundColor: rgba(darkMode ? background.sidenav : white.main, 0.8),
              backdropFilter: `saturate(200%) blur(30px)`,
            })}
          >
            <MDBox
              className='topNavbar'
              component={Link}
              to='/'
              py={transparent ? 1.5 : 0.75}
              lineHeight={1}
              pl={{ xs: 0, lg: 1 }}
            >
              <button
                className='headerLogo'
                type='button'
                style={{ color: "black", fontWeight: "bold" }}
              >
                Biz<span className='logoHighlightColor'>Go</span>.in
              </button>
            </MDBox>
            <MDBox
              className='navbarMenuWrapper'
              color='inherit'
              display={{ xs: "none", md: "flex" }}
              sx={{ gap: "3px" }}
              m={0}
              p={0}
            >
              <DefaultNavbarLink icon='home' name='Home' route='/' light={light} />

              <DefaultNavbarLink icon='' name='About' route='/about' light={light} />

              <DefaultNavbarLink icon='' name='Features' route='/features' light={light} />

              <DefaultNavbarLink icon='' name='Demo' route='/demo' light={light} />

              <DefaultNavbarLink icon='' name='Pricing' route='/pricing' light={light} />

              <DefaultNavbarLink icon='' name='Contact' route='/contact' light={light} />

              <DefaultNavbarLink
                className='redButton'
                icon=''
                name='Register'
                route='/sign-up'
                light={light}
              />

              <DefaultNavbarLink
                className='redButton'
                icon=''
                name='Log In'
                route='/sign-in'
                light={light}
              />
            </MDBox>
            {action &&
              (action.type === "internal" ? (
                <MDBox display={{ xs: "none", md: "inline-block" }}>
                  <MDButton
                    component={Link}
                    to={action.route}
                    variant='gradient'
                    color={action.color ? action.color : "info"}
                    size='small'
                  >
                    {action.label}
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox display={{ xs: "none", md: "inline-block" }}>
                  <MDButton
                    component='a'
                    href={action.route}
                    target='_blank'
                    rel='noreferrer'
                    variant='gradient'
                    color={action.color ? action.color : "info"}
                    size='small'
                    sx={{ mt: -0.3 }}
                  >
                    {action.label}
                  </MDButton>
                </MDBox>
              ))}
            <MDBox
              display={{ xs: "inline-block", md: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color='inherit'
              sx={{ cursor: "pointer" }}
              onClick={openMobileNavbar}
            >
              <Icon fontSize='default'>{mobileNavbar ? "close" : "menu"}</Icon>
            </MDBox>
          </MDBox>
        </MDBox>
      </Fade>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </>
  )
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
}

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
}

export default DefaultNavbar
