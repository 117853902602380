import * as yup from "yup"

export const schema = yup
  .object()
  .shape({
    // plan: yup.object().required("Plan Field is Required").nullable(),
    basicdetails: yup.object().shape({
      pageurl: yup
        .string()
        .required("Page URL is Required")
        .matches(/^(\S+$)/g, "Page URL cannot contain any blankspaces")
        .strict(true),
      pagetitle: yup.string().required("Page title is Required"),
      whatsappno: yup
        .string()
        .required("Whatsapp Number is Required")
        .matches(/^[6-9]\d{9}$/g, "Invalid Whatsapp Number")
        .strict(true),
    }),
  })
  .required()
