import { Modal as MuiModal, Box, IconButton, Typography, Paper } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import PropTypes from "prop-types"

const Modal = ({
  open,
  onClose,
  children,
  size = "md",
  header = null,
  footer = null,
  closeButton = true,
  vPosition = "center",
}) => {
  let vposition =
    vPosition === "center" ? "50%" : vPosition === "top" ? "10%" : vPosition === "bottom" ? "90%" : vPosition // give it as a percentage

  return (
    <MuiModal open={open} onClose={onClose} centered>
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: vposition,
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: size,
        }}
      >
        <Box p={2}>
          {closeButton && (
            <IconButton edge='end' color='inherit' onClick={onClose} sx={{ position: "absolute", top: 8, right: 15 }}>
              <CloseIcon />
            </IconButton>
          )}
          {header && (
            <Typography variant='h6' sx={{ marginBottom: 2 }}>
              {header}
            </Typography>
          )}
          <div>{children}</div>
          {footer && <div>{footer}</div>}
        </Box>
      </Paper>
    </MuiModal>
  )
}

export default Modal

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false, PropTypes.string]),
  header: PropTypes.node,
  footer: PropTypes.node,
  closeButton: PropTypes.bool,
  vPosition: PropTypes.string,
}

Modal.defaultProps = {
  size: "md",
  header: null,
  footer: null,
  closeButton: true,
  vPosition: "center",
}
