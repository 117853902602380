import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import React, { useContext } from "react"
import MDButton from "components/MDButton"
import { NewPageContext } from "."

const PageSectionWrap = ({ header, children, saveButton = false, initialOpen = false }) => {
  const [expanded, setExpanded] = React.useState(initialOpen)

  const { isMutating } = useContext(NewPageContext)

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, isExpanded) => {
        setExpanded(isExpanded ? true : false)
      }}
      square
      disableGutters
      // defaultExpanded
      sx={{
        borderRadius: "1rem",
        marginBottom: "1rem",
        border: "1px solid #ccc",
        outline: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        sx={{
          borderRadius: "8px",
          ".MuiAccordionSummary-content": {
            justifyContent: "space-between",
            alignItems: "center",
            gap: "0.5rem",
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={"order-details-content"}
        id={"order-details-header"}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>
        {children}
        {saveButton && expanded ? (
          <div className='text-center mt-2' onClick={(e) => e.stopPropagation()}>
            <MDButton
              variant='gradient'
              color='primary'
              type='submit'
              sx={{
                marginRight: "1rem",
              }}
              // onClick={(e) => {
              //   e.preventDefault()
              //   e.stopPropagation()
              // }}
            >
              {isMutating ? (
                <CircularProgress
                  disableShrink
                  sx={{
                    color: "white !important",
                  }}
                  size='1.3rem'
                />
              ) : (
                "Save"
              )}
            </MDButton>
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default PageSectionWrap
