import React from 'react'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';


const MainLayout = () => {


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={3} pb={0} sx={{
                height: "calc(100vh - 64px)",
                display: "flex", flexDirection: "column", justifyContent: "space-between"
            }}>
                <Grid px={2} container spacing={3}>
                    <Grid item xs={12}>
                        <Outlet />
                    </Grid>
                </Grid>
                <Footer />
            </MDBox>
        </DashboardLayout >

    )
}

export default MainLayout