import { Card, CardContent, CircularProgress, Grid, IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDelete } from 'hooks/useDelete';
import { queryClient } from 'index';
import { useMutate } from 'hooks/useMutate';
import theme from 'assets/theme';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';

const Cart = () => {

    const purchase = useSelector(state => state.globalState.purchase)

    const cartItems = purchase?.data ?? []
    const totalAmount = purchase?.totalAmount

    const accessToken = sessionStorage.getItem("accessToken")

    const onSuccessDelete = (res) => {
        console.log("onSuccessDelete", res)

        queryClient.invalidateQueries(["cart"])
    }

    const onErrorDelete = (err) => {
        console.log("onErrorDelete", err)
    }

    const { mutate: Delete } = useDelete("DeleteCartItem", "/payment/deletefromcart.php?productid=", onSuccessDelete, onErrorDelete)

    const onRemoveCartItem = (id) => {

        Delete(id)
    }

    const onSuccessPayment = (res) => {
        console.log("onSuccessPayment", res)
        queryClient.invalidateQueries(["cart"])
    }

    const onErrorPayment = (err) => {
        console.log("onErrorPayment", err)
    }

    const { mutate: handlePayment } = useMutate("handlePayment", "/payment/handle-payment.php", onSuccessPayment, onErrorPayment)

    const razorPayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID

    const initPayment = (data) => {
        console.log('initPayment  data:', data)

        const decodedAccessToken = jwtDecode(accessToken);

        const options = {
            "key": razorPayKeyId, // Enter the Key ID generated from the Dashboard
            "amount": data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": decodedAccessToken.data.username,
            "description": "Test Transaction",
            // "image": "https://example.com/your_logo",
            "order_id": data.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response) {
                const data = {
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                    keyid: razorPayKeyId,
                }
                handlePayment(data)
            },
            "prefill": {
                "name": decodedAccessToken.data.username,
                "contact": decodedAccessToken.data.mobile,
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": theme.palette.primary.main
            }
        }

        const rPI = new window.Razorpay(options)

        rPI.open();
    }

    const onSuccessPaymentCreation = (res) => {
        console.log("on Creating payment Response", res)

        initPayment(res.data)

    }

    const onErrorPaymentCreation = (err) => {
        console.log("on Creating Payment Error", err)
    }

    const { mutate } = useMutate("create-payment", "/payment/create-payment.php", onSuccessPaymentCreation, onErrorPaymentCreation)

    const createPayment = () => {

        const data = {
            keyid: razorPayKeyId,
        }

        mutate(data)
    }

    return (
        !purchase ?
            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </MDBox>
            :
            cartItems?.length === 0 ? (
                <EmptyCartMessage />
            ) : (
                <Grid container spacing={2}>
                    {cartItems?.map((item) => {

                        const productDetails = JSON.parse(item.productdetails)

                        return <Grid item sm={12} key={item.id}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Grid container justifyContent="start" alignItems="center">
                                        <Grid item sm={3}>
                                            <MDTypography sx={{ fontWeight: "bold" }}>{productDetails.title}</MDTypography>
                                            <MDTypography >price: {productDetails.price}₹</MDTypography>
                                        </Grid>
                                        <Grid item sm={8}>
                                            <MDTypography>{productDetails.description}</MDTypography>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <MDBox display="flex" justifyContent="flex-end">
                                                <IconButton
                                                    aria-label="Remove item from cart"
                                                    onClick={() => onRemoveCartItem(item.id)}
                                                >
                                                    <DeleteIcon color='primary' />
                                                </IconButton>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}

                    <Grid container justifyContent="center" mt={2} alignItems="center">

                        <Grid item sm={12} mb={2}>
                            <MDTypography align="center">
                                Total Amount: {totalAmount} ₹
                            </MDTypography>

                        </Grid>

                        <MDButton variant="contained" color="primary" size="medium" onClick={createPayment}>
                            Purchase Now
                        </MDButton>

                    </Grid>
                </Grid>

            )
    )
}

export default Cart


const EmptyCartMessage = () => {
    return (
        <Card variant="outlined">
            <CardContent>
                <MDBox display="flex" flexDirection="column" alignItems="center">
                    <IconButton>
                        <ShoppingCartIcon fontSize='large' />
                    </IconButton>
                    <MDTypography variant="h5" align="center">
                        Your Cart is Empty
                    </MDTypography>
                    <MDTypography align="center" sx={{ mb: 2 }}>
                        Start adding items to your cart to see them here.
                    </MDTypography>
                    <Link to="/products">
                        <MDButton variant="contained" color="primary" size="medium">
                            Browse Products
                        </MDButton>
                    </Link>
                </MDBox>
            </CardContent>
        </Card>
    );
};
