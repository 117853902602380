import { Container } from "@mui/material";
import bgImage from "assets/images/slider.jpg";
import HomePageLayout from "layouts/components/HomePageLayout";
import MainSection from "layouts/reusable/MainSection";
import StepsToUseSection from "layouts/reusable/StepsToUseSection";
import TestimonialSection from "layouts/reusable/TestimonialSection";
import { homeData } from "static/homepage/data";
import { homeMainContent } from "static/homepage/data";
import { testimonies } from "static/homepage/data";
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import homeMainContentImg from "assets/images/review-stand-image-6.jpg";

export const HomePage = () => {

    var { topic, content, image } = homeMainContent

    topic = "Why Do You Need Review Stand?";


    return (
        <HomePageLayout
            bgColor={"inherit"}
            image={bgImage}
            coverHeight={"100vh"}
            heroText={homeData.heroText}
            pageName=""
        >

            <Container>

                <Grid container className='mt-12'>

                    <Grid item xs={12} md={9}>
                        <MDBox>
                            <MDTypography sx={{ fontSize: "2rem" }} className="text-center">
                                {topic}
                            </MDTypography>

                            <div style={{ marginTop: "2rem" }} className="text-justify justify-center">
                                <p>Positive reviews are important for businesses for several reasons: </p>
                                <br></br>
                                <p><strong>Increased Trust and Credibility:</strong> Positive reviews can increase the trust and credibility of a business among potential customers. When people see positive reviews, they are more likely to believe that the business provides good products or services.</p>

                                <p><strong>Improved Online Reputation:</strong> Positive reviews can also help improve the online reputation of a business. When people search for a business online, they are likely to see reviews from previous customers. Positive reviews can help counteract any negative reviews and make the business look better overall.</p>

                                <p><strong>Increased Sales and Revenue:</strong> Positive reviews can also lead to increased sales and revenue for a business. When people see positive reviews, they are more likely to choose that business over others. This can lead to more customers and more revenue.</p>

                                <p><strong>Improved SEO:</strong> Positive reviews can also help improve the search engine optimization (SEO) of a business. When a business has more positive reviews, it is more likely to appear higher in search engine results, which can lead to more website traffic and more customers.</p>
                                <br></br>
                                <p>Overall, positive reviews are an important aspect of any business's success. They can help improve trust, credibility, online reputation, sales, revenue, and SEO.</p>

                            </div>
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <MDBox className="xs:ml-0">
                            <img className='object-cover m-4 w-full h-[600px]' src={homeMainContentImg} />
                        </MDBox>
                    </Grid>

                </Grid>


                <StepsToUseSection />

                <TestimonialSection testimonies={testimonies} />




            </Container>


        </HomePageLayout>
    );
}

