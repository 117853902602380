import { Divider } from "@mui/material"
import colors from "assets/theme/base/colors"
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart"
import { useFetch } from "hooks/useFetch"
import React from "react"

const AnalyticsChart = () => {
  const { data } = useFetch(
    "get-analytics",
    "/get-analytics.php",
    () => {},
    () => {},
    true
  )

  const dayData = data?.dayData || []

  const weeklyData = data?.weeklyData || []

  const monthlyData = data?.monthlyData || []

  const locationData = data?.locationData || []

  // const chartOptions = {
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //       stepSize: 1,
  //       ticks: {
  //         precision: 0,
  //       },
  //     },
  //   },
  // }

  return (
    <React.Fragment>
      <VerticalBarChart
        title='Daily Visits For Last 30 Days'
        chart={{
          labels: dayData?.map((entry) => entry.visitdate),
          datasets: [
            {
              label: "Total Visits",
              data: dayData?.map((entry) => parseInt(entry.totalVisits)),
              borderWidth: 1,
              backgroundColor: colors.primary.main,
            },
          ],
        }}
      />

      <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />

      <VerticalBarChart
        title='Weekly Visits For Last 12 Months'
        chart={{
          labels: weeklyData?.map((entry) => entry.week),
          datasets: [
            {
              label: "Total Visits",
              data: weeklyData?.map((entry) => parseInt(entry.totalVisits)),
              borderWidth: 1,
              backgroundColor: colors.primary.main,
            },
          ],
        }}
      />

      <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />

      <VerticalBarChart
        title='Monthly Visits For Last 12 Years'
        chart={{
          labels: monthlyData?.map((entry) => entry.month),
          datasets: [
            {
              label: "Total Visits",
              data: monthlyData?.map((entry) => parseInt(entry.totalVisits)),
              borderWidth: 1,
              backgroundColor: colors.primary.main,
            },
          ],
        }}
      />

      <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />

      <VerticalBarChart
        title='Visit From Different Locations'
        chart={{
          labels: locationData?.map((entry) => entry?.location),
          datasets: [
            {
              label: "Total Visits",
              data: locationData?.map((entry) => parseInt(entry.totalVisits)),
              borderWidth: 1,
              backgroundColor: colors.primary.main,
            },
          ],
        }}
      />
    </React.Fragment>
  )
}

export default AnalyticsChart
