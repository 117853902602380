import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

const accessToken = sessionStorage.getItem("accessToken");

const initialState = {
    accessToken: sessionStorage.getItem("accessToken") ?? "",
    username: sessionStorage.getItem("username") ?? "",
    userRole: accessToken ? jwtDecode(accessToken).data.role ?? "" : "",
    alertMessage: "",
    purchase: JSON.parse(sessionStorage.getItem("purchase")) ?? ""
}

export const globalState = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        setLoggedIn: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            sessionStorage.setItem("accessToken", action.payload);
            state.userRole = jwtDecode(action.payload).data.role
            state.accessToken = action.payload
        },
        setLogOut: (state) => {
            sessionStorage.removeItem("accessToken")
            state.accessToken = ""
            sessionStorage.removeItem("username")
            state.username = ""
            state.userRole = ""
        },
        setUserName: (state, action) => {
            sessionStorage.setItem("username", action.payload);
            state.username = action.payload
        },
        setAlertMessage: (state, action) => {
            state.alertMessage = action.payload
            console.log('action.payload', action.payload)
            toast(action.payload.message, {
                type: action.payload.type,
                position: "bottom-right"
            })
        },
        setPurchase: (state, action) => {
            sessionStorage.setItem("purchase", JSON.stringify(action.payload));
            state.purchase = action.payload
        }

    },
})

// Action creators are generated for each case reducer function
export const { setLoggedIn, setLogOut, setUserName, setAlertMessage, setPurchase } = globalState.actions

export default globalState.reducer