// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Footer from 'layouts/components/Footer';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import { useWindowSize } from 'react-use';
import { Container } from '@mui/material';

function BasicLayout({ image, children }) {
  const { height } = useWindowSize();

  return (
    <PageLayout>
      <DefaultNavbar transparent />

      <MDBox
        position="absolute"
        width="100%"
        height={height <= 730 ? '0' : '100vh'}
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6),
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />

      <Container
        sx={{
          height: height <= 730 ? 'auto' : '100vh',
          marginTop: height <= 730 ? '100px' : '0px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={6} lg={5} xl={4}>
            {/* <MDTypography
              variant="h1"
              color="light"
              textGradient
              className="-translate-y-24 text-center drop-shadow-2xl"
              width="100%"
            >
              BizGo.in
            </MDTypography> */}
            {children}
          </Grid>
        </Grid>
      </Container>

      <Footer position={'relative'} />
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
