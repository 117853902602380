import homeMainContentImg from "assets/images/homeMainContent.jpg";
import testimonials1 from "assets/images/testimonials-1.jpg";
import testimonials2 from "assets/images/testimonials-2.jpg";
import testimonials3 from "assets/images/testimonials-3.jpg";
import testimonials4 from "assets/images/testimonials-4.jpg";
import testimonials5 from "assets/images/testimonials-5.jpg";

export const homeData = {
  heroText:
    "Get Postivie Reviews for your Business... Grow Your Business Online...",
};

export const homeMainContent = {
  topic: "Why do yo need Review Stand?",
  content:
    "BizGo.in is a product of WebGlits. This product motto is to improve the reviews of a business / service. Also link the all social media links to the one link with QR. We focus the business development the concepts according to today's market. WebGLITS is a web design and development company based in Nagercoil, Kanyakumari District, TamilNadu, India. We are a dedicated team of website designing professionals with the aim of helping your business to get the maximum potential of the Internet. We power your business on the internet through wonderful and customized websites @ reasonable price.",
  image: {
    src: homeMainContentImg,
    alt: "homeMainContentIcon",
  },
};

export const aboutMainContent = {
  topic: "About BizGo.in",
  content:
    "BizGo.in is a product of WebGlits. This product motto is to improve the reviews of a business / service. Also link the all social media links to the one link with QR. We focus the business development the concepts according to today's market. WebGLITS is a web design and development company based in Nagercoil, Kanyakumari District, TamilNadu, India. We are a dedicated team of website designing professionals with the aim of helping your business to get the maximum potential of the Internet. We power your business on the internet through wonderful and customized websites @ reasonable price.",
  image: {
    src: homeMainContentImg,
    alt: "homeMainContentIcon",
  },
};

export const featureMainContent = {
  topic: "BizGo.in Features",
  content:
    "- Positive Reviews options - Prevent from online negative reviews  - Negative Reviews Monitoring  - Negative Customers Datas Options - Connect all social media links in one link / QR - Login dashboard options - Custom Themes editing options - Unique QR for your business / service",
  image: {
    src: homeMainContentImg,
    alt: "homeMainContentIcon",
  },
};

export const contactMainContent = {
  topic: "Contact BizGo.in",
  content: "For Any Questions, Contact: +91 9043022255",
  image: {
    src: homeMainContentImg,
    alt: "homeMainContentIcon",
  },
};

export const pricingMainContent = {
  topic: "BizGo.in Pricing",
  content:
    "We offer our services from Rs. 499 per year. Please Contact: +91 9043022255 for more details.",
  image: {
    src: homeMainContentImg,
    alt: "homeMainContentIcon",
  },
};

export const demoMainContent = {
  topic: "BizGo.in Demo",
  content: "",
  image: {
    src: homeMainContentImg,
    alt: "homeMainContentIcon",
  },
};

export const testimonies = [
  {
    id: 1,
    name: "RS Diagnostic Chennai",
    image: {
      src: testimonials1,
      alt: "",
    },
  },
  {
    id: 2,
    name: "Guruji Helmets",
    image: {
      src: testimonials2,
      alt: "",
    },
  },
  {
    id: 3,
    name: "Angel Keva Hair Clinic",
    image: {
      src: testimonials3,
      alt: "",
    },
  },
  {
    id: 4,
    name: "Maryam Saif Health Care",
    image: {
      src: testimonials4,
      alt: "",
    },
  },
  {
    id: 5,
    name: "Sai Nataraja Silks",
    image: {
      src: testimonials5,
      alt: "",
    },
  },
];

export const planOptions = [
  { id: 1, value: 1, label: "plan 1" },
  { id: 2, value: 2, label: "plan 2" },
];

// export const themeIds = [
//   {
//     color1: "#ffffff",
//     color2: "#00d4ff",
//     font1: "Allerta",
//     font2: "Hammersmith One",
//     background: {
//       color: "#e37d16",
//       image: "",
//       bgsize: "cover",
//       bgattachment: "fixed",
//       gradient:
//         "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, RGBA(2,0,36,1) 66%, rgba(0,212,255,1) 100%)",
//     },
//     themeid: 1,
//     // themeName: "White Theme"
//   },
//   {
//     color1: "#e37d16",
//     color2: "#e7dbbb",
//     font1: "Allerta",
//     font2: "Hammersmith One",
//     background: {
//       color: "#e37d16",
//       image: "",
//       bgsize: "cover",
//       gradient:
//         "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, RGBA(2,0,36,1) 66%, rgba(0,212,255,1) 100%)",
//     },
//     themeid: 2,
//     bgattachment: "fixed",
//     // themeName: "Dark Theme"
//   },
// ];

export const themeIdOptions = [
  {
    id: 1,
    label: "Theme 1",
    value: "1",
  },
  {
    id: 2,
    label: "Theme 1 Dark",
    value: "1dark",
  },
  {
    id: 3,
    label: "Theme 2",
    value: "2",
  },
  {
    id: 4,
    label: "Theme 2 Dark",
    value: "2dark",
  },
  {
    id: 5,
    label: "Theme 3",
    value: "3",
  },
  {
    id: 6,
    label: "Theme 3 Dark",
    value: "3dark",
  },
];
