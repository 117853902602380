import { Grid } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import DefaultNavbar from "examples/Navbars/DefaultNavbar"
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink"
import React from "react"
import Footer from "../Footer"
import { useWindowSize } from "react-use"
import { Link } from "react-router-dom"

const HomePageLayout = ({ bgColor, children, coverHeight, image, heroText, pageName }) => {
  const { height } = useWindowSize()

  return (
    <MDBox width='auto' height='100%' minHeight='100vh' bgColor={bgColor} sx={{ overflowX: "hidden" }}>
      <DefaultNavbar transparent />

      <MDBox
        sx={{ display: "grid", placeItem: "center", isolation: "center", marginTop: height <= 730 ? "68px" : "0px" }}
      >
        <MDBox
          width='calc(100%)'
          height={height <= 730 ? "auto" : `calc(${coverHeight})`}
          borderRadius='none'
          // mx={2}
          // my={2}
          pt={10}
          pb={28}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(rgba(gradients.dark.main, 0.4), rgba(gradients.dark.state, 0.4))}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 1,
            gridRowEnd: 1,
            zIndex: -1,
          }}
        />

        {heroText.length > 0 && (
          <MDBox
            width='100%'
            sx={{
              flexDirection: "column",
              gridColumnStart: 1,
              gridColumnEnd: 1,
              gridRowStart: 1,
              gridRowEnd: 1,
            }}
            className='flex justify-center items-center p-8'
          >
            <div>
              <MDTypography
                color='white'
                variant='h3'
                fontWeight='bold'
                className='heroTitle flex justify-center items-center  text-center'
              >
                {/* {heroText} */}
                Get <span className='sliderAnimateColor'>Postivie Reviews</span> for your Business...
                <br />
                Grow Your Business Online...
              </MDTypography>
            </div>

            <div className='mt-5 mb-3'>
              <Link type='button' className='redButton bigButton' to='/sign-up'>
                Start Now!
              </Link>
            </div>
          </MDBox>
        )}
      </MDBox>
      {/* {children} */}

      {pageName.length > 0 && (
        <MDBox position={"absolute"} top='20%' width='100%' className='flex justify-center items-center px-8'>
          <MDTypography
            color='white'
            variant='h2'
            fontWeight='bold'
            className='flex justify-center items-center basis-1/2 text-center'
          >
            {pageName}
          </MDTypography>
        </MDBox>
      )}

      <MDBox px={1} width='calc(100% - 2rem)' mx='auto'>
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer position={"relative"} />
    </MDBox>
  )
}

export default HomePageLayout
