import { baseUrlServer } from "services/baseUrl"

export const bizDetails = {
  basicdetails: {
    pagetitle: "",
    pageurl: "",
    tagline: "",
    logoimg: "",
    coverimg: "",
    description: "",
    whatsappno: "",
  },
  reviewdetails: {
    positivereview: true,
    reviewlink: "",
    reviewdescription: "",
    enabled: true,
    otherreviews: {
      title: "Other Review Links",
      data: [],
    },
  },
  socials: {
    enabled: true,
    title: "Social Media Links",
    data: [
      {
        iconurl: `${baseUrlServer}/images/facebook.png`,
        url: "",
        description: "",
      },
      {
        iconurl: `${baseUrlServer}/images/twitter.png`,
        url: "",
        description: "",
      },
      {
        iconurl: `${baseUrlServer}/images/instagram.png`,
        url: "",
        description: "",
      },
      {
        iconurl: `${baseUrlServer}/images/linkedin.png`,
        url: "",
        description: "",
      },
      {
        iconurl: `${baseUrlServer}/images/youtube.png`,
        url: "",
        description: "",
      },
      {
        iconurl: `${baseUrlServer}/images/whatsapp.png`,
        url: "",
        description: "",
      },
      // {
      //   iconurl: `${baseUrlServer}/images/justdial.png`,
      //   url: "",
      //   description: "", //? DUE TO SOME EXISTING DATA, THIS EXTRA SOCIAL MEDIA IS CAUSING SOME ISSUES IN FRNOTEND SO COMMENTED, USERS CAN ADD IT FROM THE SELECT OPTIONS
      // },
      {
        iconurl: `${baseUrlServer}/images/website.png`,
        url: "",
        description: "",
      },
    ],
    othersocials: [
      {
        iconurl: `${baseUrlServer}/images/others.png`,
        url: "",
        description: "",
      },
    ],
  },
  themedetails: {
    themeid: "1",
    color1: "#000333",
    color2: "#000777",
    background: {
      color: "#EAEAEA",
      image: "",
      bgsize: "cover",
      bgattachment: "fixed",
      gradient: "",
    },
    font1: "Allerta",
    font2: "Amaranth",
  },
  aboutus: {
    enabled: true,
    title: "About Us",
    description: "",
  },
  gallery: {
    enabled: true,
    title: "Gallery",
    data: [
      {
        url: "",
        notes: "",
      },
    ],
  },
  services: {
    enabled: true,
    title: "Services",
    data: [
      {
        title: "",
        description: "",
        url: "",
      },
    ],
  },
  products: {
    enabled: true,
    title: "Products",
    data: [
      {
        name: "",
        description: "",
        originalprice: "",
        offerprice: "",
        url: "",
      },
    ],
  },
  contactdetails: {
    enabled: true,
    title: "Contact Details",
    personname: "",
    designation: "",
    phone1: "",
    phone2: "",
    email: "",
    map: "",
    address: "",
  },
  paymentdetails: {
    enabled: true,
    title: "Payment Details",
    gpay: {
      image: "",
      upi: "",
    },
    phonepe: {
      image: "",
      upi: "",
    },
    paytm: {
      image: "",
      upi: "",
    },
    other: {
      image: "",
      upi: "",
    },
    bankdetails: "",
  },
  videos: {
    enabled: true,
    title: "Videos",
    data: [
      {
        url: "",
        description: "",
      },
    ],
  },
  documents: {
    enabled: true,
    title: "Documents",
    data: [
      {
        url: "",
        description: "",
      },
    ],
  },
  timings: {
    enabled: true,
    title: "Timings",
    monday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
    tuesday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
    wednesday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
    thursday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
    friday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
    saturday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
    sunday: {
      open: "9:00 AM",
      close: "6:00 PM",
    },
  },
  locationdetails: {
    enabled: true,
    title: "Our Location",
    locationlink: "",
    locationembed: "",
  },
}
