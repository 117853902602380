import { createStyles } from "@mui/material"

export const customSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    zIndex: "100",
    backgroundColor: state.isDisabled ? "#f5f5f5" : "transparent",
    // borderColor: state.isFocused ? 'grey' : 'red',
    height: "43.5px",
    fontSize: ".9rem",
    // color: darkMode ? "white" : "black",
    fontWeight: "400",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: ".9rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
    fontWeight: "400",
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({ ...base, zIndex: 9999, minWidth: "210px" }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontWeight: "400",
    width: "100px",
    ":after": {
      ...provided[":after"],
      color: "black",
      fontWeight: "400",
    },
  }),
}

export const deleteIconStyles = createStyles({
  color: "red",
  position: "absolute",
  width: 20,
  height: 20,
  right: 2,
  cursor: "pointer",
  top: 2,
  boxShadow: "0px 0px 15px 20px white",
  backgroundColor: "white !important",
})
