import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import MDInput from "components/MDInput"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { customSelectStyles } from "utilities/customStyles"

const OpenOptions = [
  { value: "holiday", label: "Holiday" },
  { value: "closed", label: "Closed" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "12:30 AM", label: "12:30 AM" },
  { value: "1:00 AM", label: "1:00 AM" },
  { value: "1:30 AM", label: "1:30 AM" },
  { value: "2:00 AM", label: "2:00 AM" },
  { value: "2:30 AM", label: "2:30 AM" },
  { value: "3:00 AM", label: "3:00 AM" },
  { value: "3:30 AM", label: "3:30 AM" },
  { value: "4:00 AM", label: "4:00 AM" },
  { value: "4:30 AM", label: "4:30 AM" },
  { value: "5:00 AM", label: "5:00 AM" },
  { value: "5:30 AM", label: "5:30 AM" },
  { value: "6:00 AM", label: "6:00 AM" },
  { value: "6:30 AM", label: "6:30 AM" },
  { value: "7:00 AM", label: "7:00 AM" },
  { value: "7:30 AM", label: "7:30 AM" },
  { value: "8:00 AM", label: "8:00 AM" },
  { value: "8:30 AM", label: "8:30 AM" },
  { value: "9:00 AM", label: "9:00 AM" },
  { value: "9:30 AM", label: "9:30 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "12:00 PM", label: "12:00 PM" },
  { value: "12:30 PM", label: "12:30 PM" },
  { value: "1:00 PM", label: "1:00 PM" },
  { value: "1:30 PM", label: "1:30 PM" },
  { value: "2:00 PM", label: "2:00 PM" },
  { value: "2:30 PM", label: "2:30 PM" },
  { value: "3:00 PM", label: "3:00 PM" },
  { value: "3:30 PM", label: "3:30 PM" },
  { value: "4:00 PM", label: "4:00 PM" },
  { value: "4:30 PM", label: "4:30 PM" },
  { value: "5:00 PM", label: "5:00 PM" },
  { value: "5:30 PM", label: "5:30 PM" },
  { value: "6:00 PM", label: "6:00 PM" },
  { value: "6:30 PM", label: "6:30 PM" },
  { value: "7:00 PM", label: "7:00 PM" },
  { value: "7:30 PM", label: "7:30 PM" },
  { value: "8:00 PM", label: "8:00 PM" },
  { value: "8:30 PM", label: "8:30 PM" },
  { value: "9:00 PM", label: "9:00 PM" },
  { value: "9:30 PM", label: "9:30 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "10:30 PM", label: "10:30 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "11:30 PM", label: "11:30 PM" },
]

const CloseOptions = [
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "11:30 PM", label: "11:30 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "10:30 PM", label: "10:30 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "9:30 PM", label: "9:30 PM" },
  { value: "9:00 PM", label: "9:00 PM" },
  { value: "8:30 PM", label: "8:30 PM" },
  { value: "8:00 PM", label: "8:00 PM" },
  { value: "7:30 PM", label: "7:30 PM" },
  { value: "7:00 PM", label: "7:00 PM" },
  { value: "6:30 PM", label: "6:30 PM" },
  { value: "6:00 PM", label: "6:00 PM" },
  { value: "5:30 PM", label: "5:30 PM" },
  { value: "5:00 PM", label: "5:00 PM" },
  { value: "4:30 PM", label: "4:30 PM" },
  { value: "4:00 PM", label: "4:00 PM" },
  { value: "3:30 PM", label: "3:30 PM" },
  { value: "3:00 PM", label: "3:00 PM" },
  { value: "2:30 PM", label: "2:30 PM" },
  { value: "2:00 PM", label: "2:00 PM" },
  { value: "1:30 PM", label: "1:30 PM" },
  { value: "1:00 PM", label: "1:00 PM" },
  { value: "12:30 PM", label: "12:30 PM" },
  { value: "12:00 PM", label: "12:00 PM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "9:30 AM", label: "9:30 AM" },
  { value: "9:00 AM", label: "9:00 AM" },
  { value: "8:30 AM", label: "8:30 AM" },
  { value: "8:00 AM", label: "8:00 AM" },
  { value: "7:30 AM", label: "7:30 AM" },
  { value: "7:00 AM", label: "7:00 AM" },
  { value: "6:30 AM", label: "6:30 AM" },
  { value: "6:00 AM", label: "6:00 AM" },
  { value: "5:30 AM", label: "5:30 AM" },
  { value: "5:00 AM", label: "5:00 AM" },
  { value: "4:30 AM", label: "4:30 AM" },
  { value: "4:00 AM", label: "4:00 AM" },
  { value: "3:30 AM", label: "3:30 AM" },
  { value: "3:00 AM", label: "3:00 AM" },
  { value: "2:30 AM", label: "2:30 AM" },
  { value: "2:00 AM", label: "2:00 AM" },
  { value: "1:30 AM", label: "1:30 AM" },
  { value: "1:00 AM", label: "1:00 AM" },
  { value: "12:30 AM", label: "12:30 AM" },
  { value: "closed", label: "Closed" },
  { value: "holiday", label: "Holiday" },
]

const TimingSection = ({ register, watch, setValue, control }) => {
  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Timings
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("timings.enabled")}
                onChange={(e) => {
                  setValue("timings.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Payment Details Title' {...register("timings.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        {/* Monday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Monday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.monday.open'
                  id='timings.monday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.monday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.monday.close'
                  id='timings.monday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.monday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.monday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Tuesday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Tuesday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.tuesday.open'
                  id='timings.tuesday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.tuesday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.tuesday.close'
                  id='timings.tuesday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.tuesday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.tuesday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Wednesday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Wednesday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.wednesday.open'
                  id='timings.wednesday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.wednesday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.wednesday.close'
                  id='timings.wednesday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.wednesday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.wednesday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Thursday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Thursday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.thursday.open'
                  id='timings.thursday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.thursday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.thursday.close'
                  id='timings.thursday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.thursday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.thursday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Friday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Friday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.friday.open'
                  id='timings.friday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.friday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.friday.close'
                  id='timings.friday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.friday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.friday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Saturday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Saturday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.saturday.open'
                  id='timings.saturday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.saturday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.saturday.close'
                  id='timings.saturday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.saturday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.saturday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Sunday */}
        <Grid item xs={12}>
          <Typography
            variant='h6'
            sx={{
              mb: "8px",
            }}
          >
            Sunday
          </Typography>
          <Grid container spacing={3} sx={{}}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Open
                </Typography>
                <Controller
                  control={control}
                  name='timings.sunday.open'
                  id='timings.sunday.open'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={OpenOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.sunday.open", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Close
                </Typography>
                <Controller
                  control={control}
                  name='timings.sunday.close'
                  id='timings.sunday.close'
                  render={({ field }) => (
                    <Select
                      isDisabled={watch("timings.sunday.open") === "holiday" ? true : false}
                      name={field.name}
                      inputRef={field.ref}
                      options={CloseOptions}
                      // eslint-disable-next-line eqeqeq
                      value={OpenOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("timings.sunday.close", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default TimingSection
