import React from "react"

import VideoSection from "./VideoSection"
import DocumentSection from "./DocumentSection"
import TimingSection from "./TimingSection"
import GallerySection from "./GallerySection"
import SocialMediaLinks from "./SocialMediaLinks"
import PaymentSection from "./PaymentSection"
import ServiceSection from "./ServiceSection"
import AboutUsSection from "./AboutUsSection"
import ProductSection from "./ProductSection"
import ContactSection from "./ContactSection"
import LocationSection from "./LocationSection"

const AdditionalDetailsSection = ({ register, control, errors, watch, setValue, config }) => {
  return (
    <>
      {/* Social Media Links */}
      <SocialMediaLinks
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* About Us */}
      <AboutUsSection register={register} control={control} errors={errors} watch={watch} setValue={setValue} />

      {/* Location Details */}
      <LocationSection register={register} control={control} errors={errors} watch={watch} setValue={setValue} />

      {/* Gallery */}
      <GallerySection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* Services */}
      <ServiceSection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* Products */}
      <ProductSection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* Contact Details */}
      <ContactSection register={register} control={control} errors={errors} watch={watch} setValue={setValue} />

      <PaymentSection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* Vidoes Section */}
      <VideoSection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* Documents Section */}
      <DocumentSection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />

      {/* Timings Section */}
      <TimingSection
        register={register}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        config={config}
      />
    </>
  )
}

export default AdditionalDetailsSection
