import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import MDInput from "components/MDInput"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { ckeditorConfig } from "utilities/config"

const AboutUsSection = ({ watch, setValue, register }) => {
  const aboutUsDescription = watch("aboutus.description")

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            About Us
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("aboutus.enabled")}
                onChange={(e) => {
                  setValue("aboutus.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='About Us Title' {...register("aboutus.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: "500",
            }}
          >
            Description
          </Typography>
          <CKEditor
            editor={ClassicEditor}
            data={aboutUsDescription}
            config={ckeditorConfig}
            onChange={(event, editor) => {
              setValue("aboutus.description", editor.getData())
            }}
          />
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default AboutUsSection
