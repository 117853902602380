export const ckeditorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      //   "imageUpload",
      "blockQuote",
      //   "insertTable",
      //   "mediaEmbed",
      "undo",
      "redo",
    ],
  },
  language: "en",
}
