// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

function Tables() {
  const { columns, rows } = authorsTableData();
  // const { columns: pColumns, rows: pRows } = projectsTableData();

  return (

    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Authors Table
        </MDTypography>
      </MDBox>
      <MDBox pt={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </MDBox>
    </Card>

    //  <Grid item xs={12}>
    //   <Card>
    //     <MDBox
    //       mx={2}
    //       mt={-3}
    //       py={3}
    //       px={2}
    //       variant="gradient"
    //       bgColor="info"
    //       borderRadius="lg"
    //       coloredShadow="info"
    //     >
    //       <MDTypography variant="h6" color="white">
    //         Projects Table
    //       </MDTypography>
    //     </MDBox>
    //     <MDBox pt={3}>
    //       <DataTable
    //         table={{ columns: pColumns, rows: pRows }}
    //         isSorted={false}
    //         entriesPerPage={false}
    //         showTotalEntries={false}
    //         noEndBorder
    //       />
    //     </MDBox>
    //   </Card>
    // </Grid>

  );
}

export default Tables;
