import React, { useEffect, useState } from "react"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"

import Card from "@mui/material/Card"
import { useFetch } from "hooks/useFetch"
import MDTypography from "components/MDTypography"
import DataTable from "examples/Tables/DataTable"
import { Link, useNavigate } from "react-router-dom"
import { Box, CircularProgress } from "@mui/material"
import MDButton from "components/MDButton"
// import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported"

const Columns = ({ content }) => {
  return (
    <MDBox
      display='flex'
      alignItems='center'
      lineHeight={1}
      sx={{
        wordBreak: "break-word",
        hyphens: "auto",
        "-moz-hyphens": "auto",
        "-ms-hyphens": "auto",
        "-webkit-hyphens": "auto",
        overflowWrap: "break-word",
        width: "max-content",
        maxWidth: "500px",
      }}
    >
      {/* <MDBox lineHeight={1}> */}
      <MDTypography display='block' variant='button' fontWeight='medium'>
        {content || "-"}
      </MDTypography>
      {/* </MDBox> */}
    </MDBox>
  )
}

// const ImgColumns = ({ content }) => {
//   return (
//     <MDBox display='flex' alignItems='center' lineHeight={1}>
//       <MDBox lineHeight={1}>
//         {content ? (
//           <img height={100} width={100} className='object-contain' src={content} alt='logo-img' />
//         ) : (
//           <ImageNotSupportedIcon />
//         )}
//       </MDBox>
//     </MDBox>
//   )
// }

const Users = () => {
  const [rows, setRows] = useState([])
  const [filterValue, setFilterValue] = useState(null)
  const [search, setSearch] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [filterStatus, setFilterStatus] = useState("")

  const navigate = useNavigate()

  const onSuccess = (response) => {
    console.log("view all Pages response", response)
  }

  const onError = (error) => {
    console.error("view all Pages error", error)
    if (error.status === 401) {
      navigate("/sign-in")
    }
  }

  const handleFilterClick = () => {
    setSearchValue(search)
    setFilterStatus(filterValue?.value)
  }

  const fetchKey = `users-search->${searchValue}_filter->${filterStatus}`

  const fetchUrl = `/get-users.php?page=1${searchValue ? `&search=${searchValue}` : ""}${
    filterStatus ? `&status=${filterStatus}` : ""
  }`

  const { isLoading, data, status } = useFetch(fetchKey, fetchUrl, onSuccess, onError)

  useEffect(() => {
    const users = data?.users || []
    users.length > 0 &&
      status === "success" &&
      setRows(
        users?.map((link, index) => {
          return {
            serialno: <Columns content={index + 1} />,
            username: <Columns content={link.username} />,
            mobile: <Columns content={link.mobile} />,
            status: <Columns content={link.status == 1 ? "Inactive" : link.status == 2 ? "Active" : "-"} />,
            regdate: <Columns content={link.created} />,
          }
        })
      )

    return () => {
      setRows([])
    }
  }, [data, status])

  const columns = [
    { Header: "Serial No.", accessor: "serialno", align: "left", id: 1, show: true },
    { Header: "User Name", accessor: "username", align: "left", id: 2, show: true },
    { Header: "Mobile", accessor: "mobile", align: "left", id: 3, show: true },
    { Header: "Status", accessor: "status", align: "left", id: 4, show: true },
    { Header: "Reg Date", accessor: "regdate", align: "left", id: 5, show: true },
  ]

  return (
    <>
      <Card>
        <MDBox mx={2} mt={-3} py={2} px={2} bgColor='inherit' borderRadius='lg' coloredShadow='dark'>
          <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <MDTypography variant='h6' color='dark'>
              All Users Information <span className='inline-block'>( Total users: {data?.total} )</span>
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox pt={3}>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={true}
              noEndBorder
              canSearch={true}
              search={search}
              setSearch={setSearch}
              handleFilterClick={handleFilterClick}
              filter={{
                canFilter: true,
                filterOptions: [
                  { value: "", label: "All" },
                  { value: 1, label: "Inactive" },
                  { value: 2, label: "Active" },
                  // { value: 3, label: "Demo" },
                ],
                filterValue,
                setFilterValue,
              }}
            />
          )}
        </MDBox>
      </Card>
    </>
  )
}

export default Users
