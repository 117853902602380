import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, IconButton, Switch, Typography } from "@mui/material"
import MDInput from "components/MDInput"
import { useFieldArray } from "react-hook-form"
import { useDispatch } from "react-redux"
import { setAlertMessage } from "redux/globalState"
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import MDButton from "components/MDButton"
import { ImageViewer } from "react-image-viewer-dv"
import FileUpload from "layouts/components/FileUpload/FileUpload"
import DeleteIcon from "@mui/icons-material/Delete"
import { deleteIconStyles } from "utilities/customStyles"

const GallerySection = ({ control, register, watch, setValue, config }) => {
  const galleryData = watch("gallery.data")

  const dispatch = useDispatch()

  const {
    append: appendGalleryImg,
    fields: galleryImgFields,
    remove: removeGalleryImg,
  } = useFieldArray({
    control: control,
    name: "gallery.data",
    keyName: "id",
    shouldUnregister: false,
  })

  const handleRemoveGalleryImg = (index) => {
    if (galleryImgFields.length === 1) {
      dispatch(setAlertMessage({ type: "error", message: "Atleast one Gallery is required" }))
      return
    }
    removeGalleryImg(index)
  }

  const handleAddGalleryImg = () => {
    if (galleryImgFields.length >= config.gallery) {
      dispatch(setAlertMessage({ type: "error", message: `Maximum ${config} Gallery is allowed` }))
      return
    }
    appendGalleryImg({ url: "", notes: "" })
  }

  const handleUploadImage = (index, imageurl) => {
    setValue(`gallery.data.${index}.url`, imageurl)
  }

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Gallery
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("gallery.enabled")}
                onChange={(e) => {
                  setValue("gallery.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Gallery Title' {...register("gallery.title", {})} />
          </FormControl>
        </Grid>
      </Grid>

      <Divider
        sx={{
          mt: "1rem",
        }}
      />

      <Grid item xs={12}>
        {galleryImgFields.map((item, index) => (
          <>
            {index > 0 && (
              <Divider
                sx={{
                  my: "rem",
                }}
              />
            )}
            {index > 0 && (
              <div className='text-end'>
                <IconButton
                  color='error'
                  title='Remove Gallery'
                  type='button'
                  onClick={() => handleRemoveGalleryImg(index)}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            )}

            <div key={item.id} className='flex flex-col gap-4 items-start'>
              {galleryData[index]?.url ? (
                <div className='mt-2 text-base'>
                  Image
                  <div className='relative'>
                    <DeleteIcon
                      sx={deleteIconStyles}
                      onClick={() => {
                        setValue(`gallery.data.${index}.url`, "")
                        dispatch(setAlertMessage({ type: "success", message: "Image Deleted Successfully" }))
                      }}
                    />
                    <ImageViewer>
                      <img className='object-cover w-52 h-auto' src={galleryData[index].url} alt={"image" + index} />
                    </ImageViewer>
                  </div>
                </div>
              ) : (
                <FileUpload
                  index={index}
                  handleUploadImage={handleUploadImage}
                  extradata={{ pageurl: watch("basicdetails.pageurl") }}
                />
              )}

              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput type='text' label='Notes' {...register(`gallery.data.${index}.notes`, {})} />
              </FormControl>
            </div>
          </>
        ))}

        <MDButton
          sx={{
            gap: "0.5rem",
            marginTop: "12px",
          }}
          color='success'
          type='button'
          size='small'
          variant='contained'
          onClick={handleAddGalleryImg}
        >
          <AddCircleOutline /> Add Gallery
        </MDButton>
      </Grid>
    </PageSectionWrap>
  )
}

export default GallerySection
