import React from "react"
import { useFieldArray } from "react-hook-form"
import { useDispatch } from "react-redux"
import { setAlertMessage } from "redux/globalState"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, IconButton, Switch, Typography } from "@mui/material"
import MDInput from "components/MDInput"
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import MDButton from "components/MDButton"
import { ImageViewer } from "react-image-viewer-dv"
import FileUpload from "layouts/components/FileUpload/FileUpload"
import { deleteIconStyles } from "utilities/customStyles"
import DeleteIcon from "@mui/icons-material/Delete"

const ServiceSection = ({ config, control, setValue, watch, register }) => {
  const dispatch = useDispatch()

  const servicesData = watch("services.data")

  const {
    append: appendServices,
    fields: servicesFields,
    remove: removeServices,
  } = useFieldArray({
    control: control,
    name: "services.data",
    keyName: "id",
    shouldUnregister: false,
  })

  const handleRemoveServices = (index) => {
    if (servicesFields.length === 1) {
      dispatch(setAlertMessage({ type: "error", message: "Atleast one service is required" }))
      return
    }
    removeServices(index)
  }

  const handleAddServices = () => {
    if (servicesFields.length >= config.services) {
      dispatch(setAlertMessage({ type: "error", message: `Maximum ${config.services} services are only allowed` }))
      return
    }
    appendServices({ title: "", description: "", url: "" })
  }

  const handleUploadImage = (index, imageurl) => {
    setValue(`services.data.${index}.url`, imageurl)
  }

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Services
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("services.enabled")}
                onChange={(e) => {
                  setValue("services.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Services Title' {...register("services.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {servicesFields.map((field, index) => (
            <>
              {index > 0 && (
                <Divider
                  sx={{
                    my: "1rem",
                  }}
                />
              )}

              {index > 0 && (
                <div className='text-end'>
                  <IconButton type='button' color='error' onClick={() => handleRemoveServices(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              )}
              <div key={field.id} className='flex flex-col gap-4 items-start'>
                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  <MDInput type='text' label='Title' {...register(`services.data.${index}.title`)} />
                </FormControl>

                {servicesData[index]?.url ? (
                  <div className='mt-2 text-base'>
                    Image
                    <div className='relative'>
                      <DeleteIcon
                        sx={deleteIconStyles}
                        onClick={() => {
                          setValue(`services.data.${index}.url`, "")
                          dispatch(setAlertMessage({ type: "success", message: "Image Deleted Successfully" }))
                        }}
                      />
                      <ImageViewer>
                        <img className='object-cover w-52 h-auto' src={servicesData[index].url} alt={"image" + index} />
                      </ImageViewer>
                    </div>
                  </div>
                ) : (
                  <FileUpload
                    index={index}
                    handleUploadImage={handleUploadImage}
                    extradata={{ pageurl: watch("basicdetails.pageurl") }}
                  />
                )}

                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  <MDInput
                    type='textarea'
                    label='Description'
                    multiline={true}
                    rows={3}
                    {...register(`services.data.${index}.description`)}
                  />
                </FormControl>
              </div>
            </>
          ))}

          <MDButton
            type='button'
            sx={{
              gap: "0.5rem",
              marginTop: "12px",
            }}
            color='success'
            size='small'
            onClick={handleAddServices}
          >
            <AddCircleOutline /> Add Service
          </MDButton>
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default ServiceSection
