import { useFetch } from 'hooks/useFetch';
import { useMutate } from 'hooks/useMutate';
import { queryClient } from 'index';
import jwtDecode from 'jwt-decode';
import ProductItem from 'layouts/reusable/ProductItem';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setAlertMessage } from 'redux/globalState';

const productsTemplate = [
    {
        id: 1,
        title: 'Product 1',
        description: 'This is the description for Product 1.',
        imageUrl: 'https://picsum.photos/300/200?random=1',
        price: 10,
        isAddedTocart: false,
        isPaid: false,
    },
    {
        id: 2,
        title: 'Product 2',
        description: 'This is the description for Product 2.',
        imageUrl: 'https://picsum.photos/300/200?random=2',
        price: 20,
        isAddedTocart: false,
        isPaid: false,
    },
    {
        id: 3,
        title: 'Product 3',
        description: 'This is the description for Product 3.',
        imageUrl: 'https://picsum.photos/300/200?random=3',
        price: 20,
        isAddedTocart: false,
        isPaid: false,
    },
];

const Products = () => {

    const [products, setProducts] = useState(productsTemplate);

    const accessToken = sessionStorage.getItem("accessToken");

    const dispatch = useDispatch();

    const onGetCartSuccess = (res) => {
        console.log(res);

        const newProducts = products.map((product) => {
            const isAddedTocart = res.data ? res.data.some((cartItem) => parseInt(cartItem.productid) === product.id) : false;
            const isPaid = res.paymenthistory.some((paymentItem) => parseInt(paymentItem.productid) === product.id);

            return {
                ...product,
                isAddedTocart,
                isPaid,
            };
        });
        setProducts(newProducts);
    };

    const onGetCartError = (error) => {
        console.log(error);
        dispatch(setAlertMessage({ type: "error", message: error.message }))
    };

    useFetch("cart", "/payment/get-cart-products.php", onGetCartSuccess, onGetCartError)

    const onAddToCartSuccess = (data) => {
        console.log(data);
        queryClient.invalidateQueries(["cart"]);

        if (data.status) {
            dispatch(setAlertMessage({ type: "success", message: data.message }))
        } else {
            dispatch(setAlertMessage({ type: "error", message: data.message }))
        }
    }

    const onAddToCartError = (err) => {
        console.log(err);
        dispatch(setAlertMessage({ type: "error", message: err.message }))
    }

    const { mutate: addToCart, isSuccess } = useMutate("addToCart", "/payment/addtocart.php", onAddToCartSuccess, onAddToCartError)

    const handleAddToCart = (id) => {
        const newProducts = products.map((product) => {
            if (product.id === id) {
                return {
                    ...product,
                    isAddedTocart: true,
                };
            }
            return product;
        });

        let product = newProducts.find((product) => product.id === id);

        const decodedAccessToken = jwtDecode(accessToken);

        let data = {
            mobile: decodedAccessToken.data.mobile,
            username: decodedAccessToken.data.username,
            productid: product.id,
            productdetails: JSON.stringify({
                title: product.title,
                description: product.description,
                price: product.price,
                // imageUrl: product.imageUrl
            })
        };

        addToCart(data);

        isSuccess && setProducts(newProducts);
    };


    return (
        <div className='grid md:grid-cols-3 gap-4'>
            {products.map((product) => (
                <ProductItem
                    key={product.id}
                    productId={product.id}
                    title={product.title}
                    description={product.description}
                    imageUrl={product.imageUrl}
                    price={product.price}
                    handleAddToCart={handleAddToCart}
                    isAddedTocart={product.isAddedTocart}
                    isPaid={product.isPaid}
                />
            ))}
        </div>
    )
}

export default Products