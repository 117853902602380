import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import MDBox from "components/MDBox"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { UIDateFormatWT, errorFinder } from "utilities/commonFunctions"
import { Box } from "@mui/system"
import MDTypography from "components/MDTypography"
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported"
import MDButton from "components/MDButton"
import { baseUrl } from "services/baseUrl"
import StarsIcon from "@mui/icons-material/Stars"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import MDInput from "components/MDInput"
import { useMutate } from "hooks/useMutate"
import { setAlertMessage } from "redux/globalState"
import { useDispatch } from "react-redux"
import { queryClient } from "index"

const ViewPage = ({ role, setAlertMessage }) => {
  const isSuperAdmin = role === "super_admin"

  const { url } = useParams()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const onSuccess = (response) => {
    console.log("View Page Response", response)
    if (response.status) {
    } else {
      dispatch(setAlertMessage({ message: response?.message, type: "error" }))
      navigate("/pages")
    }
  }

  const onError = (error) => {
    console.log("View Page Error", error)
    if (error.status) {
      navigate("/pages")
      dispatch(setAlertMessage({ message: error?.message, type: "error" }))
    }
  }

  const { isLoading, data } = useFetch(["viewpage", url], `/get-page.php?pageurl=${url}`, onSuccess, onError, !!url)

  const bizDetails = data?.page ? JSON.parse(data?.page?.bizdetails) : {}

  const DataView = ({ primary, secondary }) => {
    return (
      <ListItem sx={{ marginBottom: "5px", width: "100%", height: "auto" }}>
        <ListItemText
          sx={{ width: "50%", height: "auto" }}
          primary={
            <Chip
              sx={{ height: "auto" }}
              label={
                <MDTypography component='span' sx={{ whiteSpace: "pre-wrap" }} color='info'>
                  {primary}
                </MDTypography>
              }
            />
          }
        />
        <ListItemText
          sx={{ width: "50%", height: "auto", paddingLeft: "6px" }}
          secondary={
            <MDTypography component='span' sx={{ whiteSpace: "pre-wrap" }}>
              {secondary}
            </MDTypography>
          }
        />
      </ListItem>
    )
  }

  const { data: feedbacks } = useFetch(
    "feedbacks",
    `/get-feedbacks.php?pageurl=${url}`,
    () => {},
    () => {},
    !!url,
    (data) => data.feedbacks
  )

  const thisPageFeedBack = feedbacks && feedbacks?.filter((item) => item.pageurl === url)

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <MDBox className='flex justify-end items-center mb-4'>
        <a style={{ marginRight: "8px" }} target='_blank' rel='noreferrer' href={`${baseUrl}/${data?.page?.pageurl}`}>
          <MDButton variant='gradient' color='primary'>
            Preview Page
          </MDButton>
        </a>

        <Link to={`/new-page/${data?.page?.pageurl}`}>
          <MDButton variant='gradient' color='primary'>
            Edit Page
          </MDButton>
        </Link>
      </MDBox>

      <MDBox className='grid grid-cols-1 gap-4 md:grid-cols-2'>
        <Card className='row-span-2' sx={{ minWidth: 275 }}>
          <CardContent>
            <MDBox sx={{ marginTop: "12px", marginBottom: "12px" }}>
              <MDTypography variant='h3'>{data?.page?.pagetitle}</MDTypography>
            </MDBox>
            {/* <MDTypography component="span">
                                {data?.member?.gender === "Male" ? <ManIcon color='inherit' fontSize='large' />
                                    :
                                    data?.member?.gender === "Female" ? <WomanIcon color='inherit' fontSize='large' /> : null}
                            </MDTypography> */}

            <MDTypography component='div' variant='body2'>
              <List component='nav' aria-label='mailbox folders'>
                {bizDetails.basicdetails?.logoimg ? (
                  <div className='flex justify-center'>
                    <img src={bizDetails.basicdetails?.logoimg} alt={"Logo-pic"} className='object-cover my-3 h-64' />
                  </div>
                ) : (
                  <span className='flex justify-start pl-3 pb-2 items-baseline gap-2'>
                    <ImageNotSupportedIcon /> Logo image not provided
                  </span>
                )}

                <DataView primary={"Plan"} secondary={data?.page?.plan || "-"} />
                <DataView primary={"Page URL"} secondary={data?.page?.pageurl || "-"} />
                <DataView primary={"Page Title"} secondary={data?.page?.pagetitle || "-"} />
                {/* <DataView primary={"description"} secondary={data?.page?.description || "-"} /> */}
              </List>
            </MDTypography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 275 }}>
          <CardContent sx={{ padding: "24px" }}>
            {/* <MDTypography sx={{ marginTop: "12px", marginBottom: "12px" }} variant="h5" component="div">
                            Eric
                        </MDTypography> */}

            <MDTypography component='div' variant='body2'>
              <List component='nav' aria-label='mailbox folders'>
                {bizDetails?.basicdetails?.coverimg ? (
                  <div className='flex justify-center'>
                    <img src={bizDetails?.basicdetails?.coverimg} alt={"Cover-pic"} className='object-cover h-64' />
                  </div>
                ) : (
                  <span className='flex justify-start pl-3 pb-2 items-baseline gap-2'>
                    <ImageNotSupportedIcon /> Cover image not provided
                  </span>
                )}
              </List>
            </MDTypography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 275 }}>
          <CardContent sx={{ padding: "24px" }}>
            {/* <MDTypography sx={{ marginTop: "12px", marginBottom: "12px" }} variant="h5" component="div">
                            Eric
                        </MDTypography> */}

            <MDTypography component='div' variant='body2'>
              <List component='nav' aria-label='mailbox folders'>
                <DataView primary={"Views"} secondary={data?.page?.views || "-"} />
                <DataView primary={"Status"} secondary={data?.page?.status || "-"} />
                <DataView primary={"Added Date"} secondary={UIDateFormatWT(data?.page?.addeddate) || "-"} />
                <DataView primary={"Updated Date"} secondary={UIDateFormatWT(data?.page?.updateddate) || "-"} />
                <DataView primary={"Expiry Date"} secondary={UIDateFormatWT(data?.page?.expirydate) || "-"} />
              </List>
            </MDTypography>
          </CardContent>
        </Card>
      </MDBox>

      <MDBox mt={3}>
        <Card>
          <CardContent sx={{ padding: "24px" }}>
            <MDTypography sx={{ marginBottom: "12px" }} variant='h5' component='div'>
              Feedbacks
            </MDTypography>

            <MDTypography component='div' variant='body2'>
              {thisPageFeedBack?.length > 0 &&
                thisPageFeedBack?.map((item, index) => {
                  return <Feedback key={index} feedback={item} />
                })}
            </MDTypography>
          </CardContent>
        </Card>
      </MDBox>

      {isSuperAdmin && (
        <MDBox mt={3}>
          <Card>
            <CardContent sx={{ padding: "24px" }}>
              <MDTypography sx={{ marginBottom: "12px" }} variant='h5' component='div'>
                Page Status
              </MDTypography>

              <UpdatePageStatus pageStatus={data?.page?.status} pageUrl={data?.page?.pageurl} />
            </CardContent>
          </Card>
        </MDBox>
      )}

      {isSuperAdmin && (
        <MDBox mt={3}>
          <Card>
            <CardContent sx={{ padding: "24px" }}>
              <MDTypography sx={{ marginBottom: "12px" }} variant='h5' component='div'>
                Update Configs
              </MDTypography>

              <UpdateConfigs bizDetails={bizDetails} setAlertMessage={setAlertMessage} />
            </CardContent>
          </Card>
        </MDBox>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.globalState.userRole,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    setAlertMessage: (message) => dispatch(setAlertMessage(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProp)(ViewPage)

const Feedback = ({ feedback }) => {
  return (
    <Card sx={{ marginBottom: "12px" }}>
      <CardContent sx={{ padding: "24px" }}>
        <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
          <MDTypography sx={{ marginBottom: "12px" }} variant='h6' component='div'>
            {feedback?.feedback || "-"}
          </MDTypography>

          <Stars rating={feedback?.stars} />
        </MDBox>

        <MDBox
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            columnGap: "50px",
          }}
        >
          <MDTypography variant='body1' component='div'>
            Name: {feedback?.name || "-"}
          </MDTypography>

          <MDTypography variant='body1' component='div'>
            Date: {UIDateFormatWT(feedback?.feedbackdate) || "-"}
          </MDTypography>

          <MDTypography variant='body1' component='div'>
            Phone: {feedback?.phoneno || "-"}
          </MDTypography>
        </MDBox>
      </CardContent>
    </Card>
  )
}

const Stars = ({ rating }) => {
  const colorRating = ["#FF0000", "#FF0000", "#E1E100", "#E1E100", "green"]

  const starsColor = colorRating[parseInt(rating) - 1]

  return (
    <MDTypography
      sx={{
        marginBottom: "12px",
        display: "flex",
        gap: "2px",
        justifyContent: "center",
        alignItems: "center",
      }}
      variant='h6'
      component='div'
    >
      {Array(5)
        .fill("stars")
        .map((item, index) => {
          return (
            <StarsIcon
              sx={{ color: parseInt(rating) >= index + 1 ? starsColor : "rgba(79, 79, 79, 0.5)" }}
              fontSize='medium'
              key={index}
              name={item}
            />
          )
        })}
    </MDTypography>
  )
}

const UpdatePageStatus = ({ pageStatus, pageUrl }) => {
  const currentStatus = pageStatus

  const [value, setValue] = React.useState(currentStatus)

  const { mutate: updatePageStatus } = useMutate("update-page-status", "/update-page-status.php?pageurl=" + pageUrl)

  const handleChange = (event, newValue) => {
    if (newValue === null) return
    setValue(newValue)
    updatePageStatus(
      { status: newValue },
      {
        onSuccess: (res) => {
          if (res.status) {
            setAlertMessage({ message: res?.message, type: "success" })

            queryClient.setQueryData([["viewpage", pageUrl]], (oldData) => {
              return { ...oldData, page: { ...oldData.page, status: newValue } }
            })
          } else {
            setAlertMessage({ message: res?.message, type: "error" })
          }
        },
      }
    )
  }

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label='toggle button group'
      style={{ margin: "20px" }}
    >
      <ToggleButton
        value='1'
        aria-label='demo'
        style={{
          background: value == "1" ? "#2196f3" : "transparent",
          color: value == "1" ? "#fff" : "#000",
          fontWeight: "bold",
        }}
      >
        Demo
      </ToggleButton>
      <ToggleButton
        value='2'
        aria-label='active'
        style={{
          background: value == "2" ? "#4caf50" : "transparent",
          color: value == "2" ? "#fff" : "#000",
          fontWeight: "bold",
        }}
      >
        Active
      </ToggleButton>
      <ToggleButton
        value='3'
        aria-label='inactive'
        style={{
          background: value == "3" ? "#f44336" : "transparent",
          color: value == "3" ? "#fff" : "#000",
          fontWeight: "bold",
        }}
      >
        Inactive
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const UpdateConfigs = ({ bizDetails, setAlertMessage }) => {
  const pageurl = bizDetails?.basicdetails?.pageurl

  const { data: pageConfigRes } = useFetch(
    `page-config-${pageurl || "default"}`,
    `/get-config.php${pageurl ? `?pageurl=${pageurl}` : ""}`,
    () => {},
    () => {},
    true,
    (res) => res?.config
  )

  const pageConfig = useMemo(() => (pageConfigRes?.configs ? JSON.parse(pageConfigRes?.configs) : {}), [pageConfigRes])

  const { mutate: updateConfig } = useMutate(
    "update-config",
    `/update-config.php${pageurl ? `?pageurl=${pageurl}` : ""}`
  )

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: pageConfig,
  })

  useEffect(() => {
    if (pageConfig) reset(pageConfig)
  }, [pageConfig, reset])

  const submitForm = (data) => {
    const processedData = {
      configs: {
        bizcard: data?.bizcard,
        documents: parseInt(data?.documents),
        gallery: parseInt(data?.gallery),
        monthlyvisits: parseInt(data?.monthlyvisits),
        otherreviews: parseInt(data?.otherreviews),
        othersocials: parseInt(data?.othersocials),
        products: parseInt(data?.products),
        review: data?.review,
        services: parseInt(data?.services),
        socials: parseInt(data?.socials),
        videos: parseInt(data?.videos),
      },
    }

    updateConfig(processedData, {
      onSuccess: (res) => {
        if (res.status) {
          setAlertMessage({ message: res?.message, type: "success" })
        } else {
          setAlertMessage({ message: res?.message, type: "error" })
        }
      },
      onError: (error) => {
        setAlertMessage({ message: error?.data?.message, type: "error" })
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput
              type='text'
              label='Other Reviews'
              error={errorFinder(errors?.otherreviews)}
              {...register("otherreviews", {})}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Socials' error={errorFinder(errors?.socials)} {...register("socials", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Gallery' error={errorFinder(errors?.gallery)} {...register("gallery", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Services' error={errorFinder(errors?.services)} {...register("services", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Products' error={errorFinder(errors?.products)} {...register("products", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Videos' error={errorFinder(errors?.videos)} {...register("videos", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput
              type='text'
              label='Documents'
              error={errorFinder(errors?.documents)}
              {...register("documents", {})}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput
              type='text'
              label='Monthly Visits'
              error={errorFinder(errors?.monthlyvisits)}
              {...register("monthlyvisits", {})}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("review")}
                onChange={(e) => {
                  setValue("review", e.target.checked)
                }}
              />
            }
            label='Review'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput
              type='text'
              label='Other Socials'
              error={errorFinder(errors?.othersocials)}
              {...register("othersocials", {})}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("bizcard")}
                onChange={(e) => {
                  setValue("bizcard", e.target.checked)
                }}
              />
            }
            label='Biz Card'
          />
        </Grid>

        <Grid item xs={12} className='text-center'>
          <MDButton type='submit' variant='gradient' color='primary'>
            Update
          </MDButton>
        </Grid>
      </Grid>
    </form>
  )
}
