import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import MDInput from "components/MDInput"

const ContactSection = ({ watch, setValue, register }) => {
  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      enabled={
        <FormControlLabel
          sx={{ marginRight: "0px" }}
          onClick={(event) => event.stopPropagation()}
          control={
            <Switch
              color='primary'
              checked={watch("contactdetails.enabled")}
              onChange={(e) => {
                setValue("contactdetails.enabled", e.target.checked)
              }}
            />
          }
          label=''
        />
      }
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Contact Details
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("contactdetails.enabled")}
                onChange={(e) => {
                  setValue("contactdetails.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Contact Details Title' {...register("contactdetails.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Person Name' {...register("contactdetails.personname", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Designation' {...register("contactdetails.designation", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Phone 1' {...register("contactdetails.phone1", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Phone 2' {...register("contactdetails.phone2", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Email' {...register("contactdetails.email", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Map' {...register("contactdetails.map", {})} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput multiline rows={3} type='text' label='Address' {...register("contactdetails.address", {})} />
          </FormControl>
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default ContactSection
