import { useMutation } from "react-query"
import axios from "axios"
import { baseApiUrl } from "services/baseUrl"
import { useSelector } from "react-redux"

export const useImgMutate = (queryKey, url, onSuccess, onError, select) => {
  const accessToken = useSelector((state) => state.globalState.accessToken)

  return useMutation(
    [queryKey],
    async (data) => {
      let myFormData = new FormData()

      myFormData.append("images", data.image)
      myFormData.append("pageurl", data.pageurl)

      return await axios({
        method: "POST",
        url: `${baseApiUrl}${url}`,
        data: myFormData,
        processData: false,
        contentType: false,
        headers: {
          // "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          AUTHORIZATION: `Bearer ${accessToken}`,
        },
      }).then((res) => {
        return res.data
      })
    },
    {
      onSuccess,
      onError,
      // cacheTime: 30000,
      select: select,
    }
  )
}
