import { useState } from "react"

// @mui material components
import Card from "@mui/material/Card"
import CloseIcon from "@mui/icons-material/Close"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDInput from "components/MDInput"
import MDButton from "components/MDButton"

import BasicLayout from "layouts/components/BasicLayout"

// Images
import bgImage from "assets/images/bg-desktop.jpg"
import { useDispatch } from "react-redux"
import { setAlertMessage, setLoggedIn } from "redux/globalState"
import { setUserName } from "redux/globalState"
import { Alert, CircularProgress, Collapse, FormHelperText, IconButton } from "@mui/material"
import { useMutate } from "hooks/useMutate"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { Link, useNavigate } from "react-router-dom"
import { errorFinder } from "utilities/commonFunctions"
import OTPInput, { ResendOTP } from "otp-input-react"

const initialFormData = {
  mobile: "",
  password: "",
}

const schema = yup
  .object()
  .shape({
    mobile: yup.string().required("Mobile Number is Required"),
    // password: yup.string().required("Password is Required"),
  })
  .required()

export const SignIn = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormData,
    shouldUnregister: false,
  })

  const initialErrorAlertState = { message: "", state: false }
  const [errorAlert, setErrorAlert] = useState(initialErrorAlertState)
  // const [formData, setFormData] = useState(initialFormData)
  const [otpVerification, setOtpVerification] = useState(false)

  const [OTP, setOTP] = useState("")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSuccess = async (response) => {
    //handle success
    if (response.status) {
      setOtpVerification(true)
      // dispatch(setLoggedIn(response.access_token))
      // dispatch(setUserName(response.username))
      // navigate("/dashboard")
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }

  const onError = async (error) => {
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error)
    if (error.status === 401) {
      navigate("/sign-in")
    }
  }

  const { mutate, isLoading } = useMutate("sign-in", `/pre-login.php`, onSuccess, onError)

  const onOTPSuccess = async (response) => {
    //handle success
    console.log("sign-up Response", response)
    if (response.status) {
      // navigate("/sign-in")
      dispatch(setLoggedIn(response.access_token))
      dispatch(setUserName(response.username))
      dispatch(setAlertMessage({ type: "success", message: response.message }))
      navigate("/pages")
    } else {
      setErrorAlert({ message: response.message, state: true })
    }
  }

  const onOTPErro = async (error) => {
    console.log("Sign-up Error", error)
    //handle error
    setErrorAlert({ message: error.message, state: true })
    console.log(error)
  }

  const { mutate: mutateOtp, isLoading: isOtpLoading } = useMutate("otp-login", `/login.php`, onOTPSuccess, onOTPErro)

  const handleFormSubmit = (e) => {
    // let bodyFormData = new FormData()
    // bodyFormData.append("mobile", e.mobile)
    // bodyFormData.append("password", e.password)

    let data = {
      mobile: e.mobile,
    }

    mutate(data)
  }

  const handleOTPSubmit = (e) => {
    e.preventDefault()

    let bodyData = {
      mobile: getValues("mobile"),
      otp: OTP,
    }

    if (OTP.length === 4) {
      mutateOtp(bodyData)
    } else {
      setErrorAlert({ message: "Enter the 4-digit OTP", state: true })
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox bgColor='inherit' borderRadius='lg' coloredShadow='dark' mx={2} mt={-3} p={2} mb={1} textAlign='center'>
          <MDTypography variant='h4' fontWeight='medium' color='dark' mt={1}>
            Log In
          </MDTypography>

          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>

        <MDBox width={"100%"}>
          <Collapse sx={{ marginBottom: "-25px" }} in={errorAlert.state}>
            <Alert
              variant='filled'
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setErrorAlert(initialErrorAlertState)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlert.message}
            </Alert>
          </Collapse>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          {otpVerification ? (
            <MDBox component='form' role='form' onSubmit={handleOTPSubmit}>
              {/* //?OTP Form */}
              <MDTypography display='flex' justifyContent='center' sx={{ fontSize: "1rem", paddingBottom: "1rem" }}>
                Enter the OTP send to your Registered Mobile
              </MDTypography>

              <MDBox className='flex justify-center items-center' mb={2}>
                <OTPInput
                  name='otp'
                  inputStyles={{
                    marginRight: "20px",
                    marginLeft: "20px",
                    border: "1px solid black",
                    borderRadius: ".25rem",
                  }}
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType='number'
                  disabled={false}
                  // secure
                />
                {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
              </MDBox>

              <MDTypography sx={{ fontSize: "1rem", textAlign: "center" }}>
                Contact us if you didn't get OTP <br />
                <a style={{ color: "#1a73e8" }} href='tel:+919043022255'>
                  +91 9043022255
                </a>
              </MDTypography>

              <MDBox mt={1} mb={1} sx={{ textAlign: "center" }}>
                <MDButton type='submit' variant='gradient' color='primary'>
                  {isOtpLoading ? <CircularProgress color='inherit' /> : "Verify"}
                </MDButton>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox component='form' role='form' onSubmit={handleSubmit(handleFormSubmit)}>
              <MDBox mb={2}>
                <MDInput
                  error={errorFinder(errors.mobile)}
                  {...register("mobile")}
                  type='text'
                  label='Mobile'
                  fullWidth
                />
                {errors.mobile && (
                  <FormHelperText error id='mobile-helper-text'>
                    {errors.mobile?.message}
                  </FormHelperText>
                )}
              </MDBox>
              {/* <MDBox mb={2}>
              <MDInput
                error={errorFinder(errors.password)}
                {...register("password")}
                type='password'
                label='Password'
                fullWidth
              />
              {errors.password && (
                <FormHelperText error id='password-helper-text'>
                  {errors.password?.message}
                </FormHelperText>
              )}
            </MDBox> */}
              <MDBox mt={4} mb={1} sx={{ textAlign: "center" }}>
                <MDButton sx={{ fontSize: "1.1rem" }} type='submit' variant='gradient' color='primary'>
                  {isLoading ? <CircularProgress color='inherit' /> : "Submit"}
                </MDButton>
              </MDBox>
            </MDBox>
          )}

          <MDBox mt={3} textAlign='center'>
            <MDTypography variant='button' color='text'>
              Don't have Account?{" "}
              <MDTypography
                component={Link}
                to='/sign-up'
                variant='button'
                color='primary'
                fontWeight='medium'
                textGradient
              >
                Register Now
              </MDTypography>
            </MDTypography>
          </MDBox>

          <MDBox textAlign='center'>
            <MDTypography
              component={Link}
              to='/reset-password'
              variant='button'
              color='primary'
              fontWeight='medium'
              textGradient
            >
              Forgot Password?
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}
