import React, { useEffect, useMemo, useRef } from "react"
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material"
import { errorFinder } from "utilities/commonFunctions"
import MDInput from "components/MDInput"
import { useImgMutate } from "hooks/useImgMutate"
import DeleteIcon from "@mui/icons-material/Delete"
import { useMutate } from "hooks/useMutate"
import PageSectionWrap from "../PageSectionWrap"
import { customSelectStyles, deleteIconStyles } from "utilities/customStyles"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { themeIdOptions } from "static/homepage/data"
import ColorPicker from "react-best-gradient-color-picker"
import { ImageViewer } from "react-image-viewer-dv"
import MDButton from "components/MDButton"
import Modal from "layouts/components/Modal/Modal"
import { setAlertMessage } from "redux/globalState"

const formatOptionLabel = ({ label }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ fontFamily: label }}>{label}</div>
    </div>
  )
}

const objectFitOptions = [
  { value: "cover", label: "cover" },
  { value: "contain", label: "contain" },
  { value: "fill", label: "fill" },
  { value: "none", label: "none" },
  { value: "scale-down", label: "scale-down" },
]

const bgAttachmentOptions = [
  { value: "scroll", label: "scroll" },
  { value: "fixed", label: "fixed" },
  { value: "local", label: "local" },
]

const ImgLoader = ({ load }) => {
  if (load)
    return (
      <Grid item xs={12}>
        <div className='text-center'>
          <CircularProgress size='0.8rem' />
          <div>Uploading Image...</div>
        </div>
      </Grid>
    )
}

const BasicDetailsSection = ({
  watch,
  control,
  setValue,
  register,
  errors,
  setError,
  clearErrors,
  editablePage,
  config,
  url,
  setFocus,
  isMutating,
}) => {
  console.log("errors-->", errors)
  const pagetitle = watch("basicdetails.pagetitle")

  const pageurl = watch("basicdetails.pageurl")

  const logoimg = watch("basicdetails.logoimg")

  const logoImgPresent = logoimg !== ""

  const coverimg = watch("basicdetails.coverimg")

  const coverImgPresent = coverimg !== ""

  const backgroundimg = watch("themedetails.background.image")

  const backgroundImgPresent = backgroundimg !== ""

  const gradient = watch("themedetails.background.gradient")

  const [showColorPicker, setShowColorPicker] = React.useState(false)
  const [lastCheckedTitle, setLastCheckedTitle] = React.useState("")
  const [lastCheckedPageUrl, setLastCheckedPageUrl] = React.useState("")

  const { mutate: mutageImg } = useImgMutate(
    "imageUpload",
    "/api-upload-image-aws.php",
    () => {},
    () => {}
  )

  const [imgloader, setImgLoader] = React.useState({
    coverimg: false,
    logoimg: false,
    backgroundimg: false,
  })

  const imageUpload = (file, name) => {
    if (name === "coverimg") {
      setImgLoader((prev) => ({ ...prev, coverimg: true }))
      mutageImg(
        {
          image: file,
          pageurl: pageurl,
        },
        {
          onSuccess: (res) => {
            setImgLoader((prev) => ({ ...prev, coverimg: false }))
            setValue("basicdetails.coverimg", res?.imgurl)
          },
        }
      )
    }

    if (name === "logoimg") {
      setImgLoader((prev) => ({ ...prev, logoimg: true }))
      mutageImg(
        {
          image: file,
          pageurl: pageurl,
        },
        {
          onSuccess: (res) => {
            setImgLoader((prev) => ({ ...prev, logoimg: false }))
            setValue("basicdetails.logoimg", res?.imgurl)
          },
        }
      )
    }

    if (name === "backgroundimg") {
      setImgLoader((prev) => ({ ...prev, backgroundimg: true }))
      mutageImg(
        {
          image: file,
          pageurl: pageurl,
        },
        {
          onSuccess: (res) => {
            setImgLoader((prev) => ({ ...prev, backgroundimg: false }))
            setValue("themedetails.background.image", res?.imgurl)
          },
        }
      )
    }
  }

  const { mutate: mutatePageTitle } = useMutate(
    "get-pageurl",
    "/get-page-url.php",
    (res) => {
      if (res?.status) {
        setLastCheckedTitle(res?.pagetitle)
        if (!url) setValue("basicdetails.pageurl", res?.pageurl)
        // setFocus("basicdetails.pageurl")
      } else {
        setAlertMessage({ type: "error", message: res?.message })
      }
    },
    () => {}
  )

  const { mutate: mutateIsPageExist } = useMutate(
    "isPageExist",
    "/check-page-exist.php",
    () => {},
    () => {}
  )

  const checkIfPageExist = () => {
    mutateIsPageExist(
      { pageurl: pageurl },
      {
        onSuccess: (res) => {
          setLastCheckedPageUrl(pageurl)
          if (res.isPageExist && !editablePage) {
            setError("basicdetails.pageurl", {
              type: "manual",
              message: "This Page Url is already taken",
            })
          } else {
            clearErrors("basicdetails.pageurl")
          }
        },
      }
    )
  }

  const fontOptions = useMemo(() => {
    return [
      { id: "1", value: "1", font: "Aclonica", label: "Aclonica" },
      { id: "2", value: "2", font: "Allan", label: "Allan" },
      { id: "3", value: "3", font: "Annie Use Your Telescope", label: "Annie Use Your Telescope" },
      { id: "4", value: "4", font: "Anonymous Pro", label: "Anonymous Pro" },
      { id: "5", value: "5", font: "Allerta Stencil", label: "Allerta Stencil" },
      { id: "6", value: "6", font: "Allerta", label: "Allerta" },
      { id: "7", value: "7", font: "Amaranth", label: "Amaranth" },
      { id: "8", value: "8", font: "Anton", label: "Anton" },
      { id: "9", value: "9", font: "Architects Daughter", label: "Architects Daughter" },
      { id: "10", value: "10", font: "Arimo", label: "Arimo" },
      { id: "11", value: "11", font: "Artifika", label: "Artifika" },
      { id: "12", value: "12", font: "Arvo", label: "Arvo" },
      { id: "13", value: "13", font: "Asset", label: "Asset" },
      { id: "14", value: "14", font: "Astloch", label: "Astloch" },
      { id: "15", value: "15", font: "Bangers", label: "Bangers" },
      { id: "16", value: "16", font: "Bentham", label: "Bentham" },
      { id: "17", value: "17", font: "Bevan", label: "Bevan" },
      { id: "18", value: "18", font: "Bigshot One", label: "Bigshot One" },
      { id: "19", value: "19", font: "Bowlby One", label: "Bowlby One" },
      { id: "20", value: "20", font: "Bowlby One SC", label: "Bowlby One SC" },
      { id: "21", value: "21", font: "Brawler", label: "Brawler" },
      { id: "22", value: "22", font: "Buda; font-weight: 300", label: "Buda:300" },
      { id: "23", value: "23", font: "Cabin", label: "Cabin" },
      { id: "24", value: "24", font: "Calligraffitti", label: "Calligraffitti" },
      { id: "25", value: "25", font: "Candal", label: "Candal" },
      { id: "26", value: "26", font: "Cantarell", label: "Cantarell" },
      { id: "27", value: "27", font: "Cardo", label: "Cardo" },
      { id: "28", value: "28", font: "Carter One", label: "Carter One" },
      { id: "29", value: "29", font: "Caudex", label: "Caudex" },
      { id: "30", value: "30", font: "Cedarville Cursive", label: "Cedarville Cursive" },
      { id: "31", value: "31", font: "Cherry Cream Soda", label: "Cherry Cream Soda" },
      { id: "32", value: "32", font: "Chewy", label: "Chewy" },
      { id: "33", value: "33", font: "Coda", label: "Coda" },
      { id: "34", value: "34", font: "Coming Soon", label: "Coming Soon" },
      { id: "35", value: "35", font: "Copse", label: "Copse" },
      { id: "36", value: "36", font: "Corben; font-weight: 700", label: "Corben:700" },
      { id: "37", value: "37", font: "Cousine", label: "Cousine" },
      { id: "38", value: "38", font: "Covered By Your Grace", label: "Covered By Your Grace" },
      { id: "39", value: "39", font: "Crafty Girls", label: "Crafty Girls" },
      { id: "40", value: "40", font: "Crimson Text", label: "Crimson Text" },
      { id: "41", value: "41", font: "Crushed", label: "Crushed" },
      { id: "42", value: "42", font: "Cuprum", label: "Cuprum" },
      { id: "43", value: "43", font: "Damion", label: "Damion" },
      { id: "44", value: "44", font: "Dancing Script", label: "Dancing Script" },
      { id: "45", value: "45", font: "Dawning of a New Day", label: "Dawning of a New Day" },
      { id: "46", value: "46", font: "Didact Gothic", label: "Didact Gothic" },
      { id: "47", value: "47", font: "Droid Sans", label: "Droid Sans" },
      { id: "48", value: "48", font: "Droid Sans Mono", label: "Droid Sans Mono" },
      { id: "49", value: "49", font: "Droid Serif", label: "Droid Serif" },
      { id: "50", value: "50", font: "EB Garamond", label: "EB Garamond" },
      { id: "51", value: "51", font: "Expletus Sans", label: "Expletus Sans" },
      { id: "52", value: "52", font: "Fontdiner Swanky", label: "Fontdiner Swanky" },
      { id: "53", value: "53", font: "Forum", label: "Forum" },
      { id: "54", value: "54", font: "Francois One", label: "Francois One" },
      { id: "55", value: "55", font: "Geo", label: "Geo" },
      { id: "56", value: "56", font: "Give You Glory", label: "Give You Glory" },
      { id: "57", value: "57", font: "Goblin One", label: "Goblin One" },
      { id: "58", value: "58", font: "Goudy Bookletter 1911", label: "Goudy Bookletter 1911" },
      { id: "59", value: "59", font: "Gravitas One", label: "Gravitas One" },
      { id: "60", value: "60", font: "Gruppo", label: "Gruppo" },
      { id: "61", value: "61", font: "Hammersmith One", label: "Hammersmith One" },
      { id: "62", value: "62", font: "Holtwood One SC", label: "Holtwood One SC" },
      { id: "63", value: "63", font: "Homemade Apple", label: "Homemade Apple" },
      { id: "64", value: "64", font: "Inconsolata", label: "Inconsolata" },
      { id: "65", value: "65", font: "Indie Flower", label: "Indie Flower" },
      { id: "66", value: "66", font: "IM Fell DW Pica", label: "IM Fell DW Pica" },
      { id: "67", value: "67", font: "IM Fell DW Pica SC", label: "IM Fell DW Pica SC" },
      { id: "68", value: "68", font: "IM Fell Double Pica", label: "IM Fell Double Pica" },
      { id: "69", value: "69", font: "IM Fell Double Pica SC", label: "IM Fell Double Pica SC" },
      { id: "70", value: "70", font: "IM Fell English", label: "IM Fell English" },
      { id: "71", value: "71", font: "IM Fell English SC", label: "IM Fell English SC" },
      { id: "72", value: "72", font: "IM Fell French Canon", label: "IM Fell French Canon" },
      { id: "73", value: "73", font: "IM Fell French Canon SC", label: "IM Fell French Canon SC" },
      { id: "74", value: "74", font: "IM Fell Great Primer", label: "IM Fell Great Primer" },
      { id: "75", value: "75", font: "IM Fell Great Primer SC", label: "IM Fell Great Primer SC" },
      { id: "76", value: "76", font: "Irish Grover", label: "Irish Grover" },
      { id: "77", value: "77", font: "Irish Growler", label: "Irish Growler" },
      { id: "78", value: "78", font: "Istok Web", label: "Istok Web" },
      { id: "79", value: "79", font: "Josefin Sans", label: "Josefin Sans" },
      { id: "80", value: "80", font: "Josefin Slab", label: "Josefin Slab" },
      { id: "81", value: "81", font: "Judson", label: "Judson" },
      { id: "82", value: "82", font: "Jura", label: "Jura" },
      { id: "83", value: "83", font: "Jura; font-weight: 500", label: "Jura:500" },
      { id: "84", value: "84", font: "Jura; font-weight: 600", label: "Jura:600" },
      { id: "85", value: "85", font: "Just Another Hand", label: "Just Another Hand" },
      { id: "86", value: "86", font: "Just Me Again Down Here", label: "Just Me Again Down Here" },
      { id: "87", value: "87", font: "Kameron", label: "Kameron" },
      { id: "88", value: "88", font: "Kenia", label: "Kenia" },
      { id: "89", value: "89", font: "Kranky", label: "Kranky" },
      { id: "90", value: "90", font: "Kreon", label: "Kreon" },
      { id: "91", value: "91", font: "Kristi", label: "Kristi" },
      { id: "92", value: "92", font: "La Belle Aurore", label: "La Belle Aurore" },
      { id: "93", value: "93", font: "Lato; font-weight: 100", label: "Lato:100" },
      { id: "94", value: "94", font: "Lato; font-weight: 300", label: "Lato:300" },
      { id: "95", value: "95", font: "Lato", label: "Lato" },
      { id: "96", value: "96", font: "Lato; font-weight: bold", label: "Lato:bold" },
      { id: "97", value: "97", font: "Lato; font-weight: 900", label: "Lato:900" },
      { id: "98", value: "98", font: "League Script", label: "League Script" },
      { id: "99", value: "99", font: "Lekton", label: "Lekton" },
      { id: "100", value: "100", font: "Limelight", label: "Limelight" },
      { id: "101", value: "101", font: "Lobster", label: "Lobster" },
      { id: "102", value: "102", font: "Lobster Two", label: "Lobster Two" },
      { id: "103", value: "103", font: "Lora", label: "Lora" },
      { id: "104", value: "104", font: "Love Ya Like A Sister", label: "Love Ya Like A Sister" },
      { id: "105", value: "105", font: "Loved by the King", label: "Loved by the King" },
      { id: "106", value: "106", font: "Luckiest Guy", label: "Luckiest Guy" },
      { id: "107", value: "107", font: "Maiden Orange", label: "Maiden Orange" },
      { id: "108", value: "108", font: "Mako", label: "Mako" },
      { id: "109", value: "109", font: "Maven Pro", label: "Maven Pro" },
      { id: "110", value: "110", font: "Maven Pro; font-weight: 500", label: "Maven Pro:500" },
      { id: "111", value: "111", font: "Maven Pro; font-weight: 700", label: "Maven Pro:700" },
      { id: "112", value: "112", font: "Maven Pro; font-weight: 900", label: "Maven Pro:900" },
      { id: "113", value: "113", font: "Meddon", label: "Meddon" },
      { id: "114", value: "114", font: "MedievalSharp", label: "MedievalSharp" },
      { id: "115", value: "115", font: "Megrim", label: "Megrim" },
      { id: "116", value: "116", font: "Merriweather", label: "Merriweather" },
      { id: "117", value: "117", font: "Metrophobic", label: "Metrophobic" },
      { id: "118", value: "118", font: "Michroma", label: "Michroma" },
      { id: "119", value: "119", font: "Miltonian Tattoo", label: "Miltonian Tattoo" },
      { id: "120", value: "120", font: "Miltonian", label: "Miltonian" },
      { id: "121", value: "121", font: "Modern Antiqua", label: "Modern Antiqua" },
      { id: "122", value: "122", font: "Monofett", label: "Monofett" },
      { id: "123", value: "123", font: "Molengo", label: "Molengo" },
      { id: "124", value: "124", font: "Mountains of Christmas", label: "Mountains of Christmas" },
      { id: "125", value: "125", font: "Muli; font-weight: 300", label: "Muli:300" },
      { id: "126", value: "126", font: "Muli", label: "Muli" },
      { id: "127", value: "127", font: "Neucha", label: "Neucha" },
      { id: "128", value: "128", font: "Neuton", label: "Neuton" },
      { id: "129", value: "129", font: "News Cycle", label: "News Cycle" },
      { id: "130", value: "130", font: "Nixie One", label: "Nixie One" },
      { id: "131", value: "131", font: "Nobile", label: "Nobile" },
      { id: "132", value: "132", font: "Nova Cut", label: "Nova Cut" },
      { id: "133", value: "133", font: "Nova Flat", label: "Nova Flat" },
      { id: "134", value: "134", font: "Nova Mono", label: "Nova Mono" },
      { id: "135", value: "135", font: "Nova Oval", label: "Nova Oval" },
      { id: "136", value: "136", font: "Nova Round", label: "Nova Round" },
      { id: "137", value: "137", font: "Nova Script", label: "Nova Script" },
      { id: "138", value: "138", font: "Nova Slim", label: "Nova Slim" },
      { id: "139", value: "139", font: "Nova Square", label: "Nova Square" },
      { id: "140", value: "140", font: "Nunito; font-weight: light", label: "Nunito:light" },
      { id: "141", value: "141", font: "Nunito", label: "Nunito" },
      { id: "142", value: "142", font: "OFL Sorts Mill Goudy TT", label: "OFL Sorts Mill Goudy TT" },
      { id: "143", value: "143", font: "Old Standard TT", label: "Old Standard TT" },
      { id: "144", value: "144", font: "Open Sans; font-weight: 300", label: "Open Sans:300" },
      { id: "145", value: "145", font: "Open Sans", label: "Open Sans" },
      { id: "146", value: "146", font: "Open Sans; font-weight: 600", label: "Open Sans:600" },
      { id: "147", value: "147", font: "Open Sans; font-weight: 800", label: "Open Sans:800" },
      { id: "148", value: "148", font: "Open Sans Condensed; font-weight: 300", label: "Open Sans Condensed:300" },
      { id: "149", value: "149", font: "Orbitron", label: "Orbitron" },
      { id: "150", value: "150", font: "Orbitron; font-weight: 500", label: "Orbitron:500" },
      { id: "151", value: "151", font: "Orbitron; font-weight: 700", label: "Orbitron:700" },
      { id: "152", value: "152", font: "Orbitron; font-weight: 900", label: "Orbitron:900" },
      { id: "153", value: "153", font: "Oswald", label: "Oswald" },
      { id: "154", value: "154", font: "Over the Rainbow", label: "Over the Rainbow" },
      { id: "155", value: "155", font: "Reenie Beanie", label: "Reenie Beanie" },
      { id: "156", value: "156", font: "Pacifico", label: "Pacifico" },
      { id: "157", value: "157", font: "Patrick Hand", label: "Patrick Hand" },
      { id: "158", value: "158", font: "Paytone One", label: "Paytone One" },
      { id: "159", value: "159", font: "Permanent Marker", label: "Permanent Marker" },
      { id: "160", value: "160", font: "Philosopher", label: "Philosopher" },
      { id: "161", value: "161", font: "Play", label: "Play" },
      { id: "162", value: "162", font: "Playfair Display", label: "Playfair Display" },
      { id: "163", value: "163", font: "Podkova", label: "Podkova" },
      { id: "164", value: "164", font: "PT Sans", label: "PT Sans" },
      { id: "165", value: "165", font: "PT Sans Narrow", label: "PT Sans Narrow" },
      {
        id: "166",
        value: "166",
        font: "PT Sans Narrow; font-weight: regularbold",
        label: "PT Sans Narrow:regularbold",
      },
      { id: "167", value: "167", font: "PT Serif", label: "PT Serif" },
      { id: "168", value: "168", font: "PT Serif Caption", label: "PT Serif Caption" },
      { id: "169", value: "169", font: "Puritan", label: "Puritan" },
      { id: "170", value: "170", font: "Quattrocento", label: "Quattrocento" },
      { id: "171", value: "171", font: "Quattrocento Sans", label: "Quattrocento Sans" },
      { id: "172", value: "172", font: "Radley", label: "Radley" },
      { id: "173", value: "173", font: "Raleway; font-weight: 100", label: "Raleway:100" },
      { id: "174", value: "174", font: "Redressed", label: "Redressed" },
      { id: "175", value: "175", font: "Rock Salt", label: "Rock Salt" },
      { id: "176", value: "176", font: "Rokkitt", label: "Rokkitt" },
      { id: "177", value: "177", font: "Ruslan Display", label: "Ruslan Display" },
      { id: "178", value: "178", font: "Schoolbell", label: "Schoolbell" },
      { id: "179", value: "179", font: "Shadows Into Light", label: "Shadows Into Light" },
      { id: "180", value: "180", font: "Shanti", label: "Shanti" },
      { id: "181", value: "181", font: "Sigmar One", label: "Sigmar One" },
      { id: "182", value: "182", font: "Six Caps", label: "Six Caps" },
      { id: "183", value: "183", font: "Slackey", label: "Slackey" },
      { id: "184", value: "184", font: "Smythe", label: "Smythe" },
      { id: "185", value: "185", font: "Sniglet; font-weight: 800", label: "Sniglet:800" },
      { id: "186", value: "186", font: "Special Elite", label: "Special Elite" },
      { id: "187", value: "187", font: "Stardos Stencil", label: "Stardos Stencil" },
      { id: "188", value: "188", font: "Sue Ellen Francisco", label: "Sue Ellen Francisco" },
      { id: "189", value: "189", font: "Sunshiney", label: "Sunshiney" },
      { id: "190", value: "190", font: "Swanky and Moo Moo", label: "Swanky and Moo Moo" },
      { id: "191", value: "191", font: "Syncopate", label: "Syncopate" },
      { id: "192", value: "192", font: "Tangerine", label: "Tangerine" },
      { id: "193", value: "193", font: "Tenor Sans", label: "Tenor Sans" },
      { id: "194", value: "194", font: "Terminal Dosis Light", label: "Terminal Dosis Light" },
      { id: "195", value: "195", font: "The Girl Next Door", label: "The Girl Next Door" },
      { id: "196", value: "196", font: "Tinos", label: "Tinos" },
      { id: "197", value: "197", font: "Ubuntu", label: "Ubuntu" },
      { id: "198", value: "198", font: "Ultra", label: "Ultra" },
      { id: "199", value: "199", font: "Unkempt", label: "Unkempt" },
      { id: "200", value: "200", font: "UnifrakturCook; font-weight: bold", label: "UnifrakturCook:bold" },
      { id: "201", value: "201", font: "UnifrakturMaguntia", label: "UnifrakturMaguntia" },
      { id: "202", value: "202", font: "Varela", label: "Varela" },
      { id: "203", value: "203", font: "Varela Round", label: "Varela Round" },
      { id: "204", value: "204", font: "Vibur", label: "Vibur" },
      { id: "205", value: "205", font: "Vollkorn", label: "Vollkorn" },
      { id: "206", value: "206", font: "VT323", label: "VT323" },
      { id: "207", value: "207", font: "Waiting for the Sunrise", label: "Waiting for the Sunrise" },
      { id: "208", value: "208", font: "Wallpoet", label: "Wallpoet" },
      { id: "209", value: "209", font: "Walter Turncoat", label: "Walter Turncoat" },
      { id: "210", value: "210", font: "Wire One", label: "Wire One" },
      { id: "211", value: "211", font: "Yanone Kaffeesatz", label: "Yanone Kaffeesatz" },
      { id: "212", value: "212", font: "Yanone Kaffeesatz; font-weight: 300", label: "Yanone Kaffeesatz:300" },
      { id: "213", value: "213", font: "Yanone Kaffeesatz; font-weight: 400", label: "Yanone Kaffeesatz:400" },
      { id: "214", value: "214", font: "Yanone Kaffeesatz; font-weight: 700", label: "Yanone Kaffeesatz:700" },
      { id: "215", value: "215", font: "Yeseva One", label: "Yeseva One" },
      { id: "216", value: "216", font: "Zeyada", label: "Zeyada" },
    ]
  }, [])

  return (
    <>
      <PageSectionWrap
        initialOpen={true}
        saveButton
        header={
          <>
            <Typography fontWeight={500} variant='h5'>
              Bussiness Details
            </Typography>
          </>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              onBlur={() => {
                if (pagetitle !== lastCheckedTitle) mutatePageTitle({ pagetitle: pagetitle })
              }}
              variant='outlined'
              sx={{ width: "100%" }}
            >
              <MDInput
                type='text'
                label='Biz Name *'
                error={errorFinder(errors?.basicdetails?.pagetitle)}
                {...register("basicdetails.pagetitle", {})}
              />
              {errors?.basicdetails?.pagetitle && (
                <FormHelperText error id='basicdetails.pagetitle-helper-text'>
                  {errors?.basicdetails?.pagetitle?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* TagLine */}
          <Grid item xs={12} sm={6}>
            <FormControl variant='outlined' sx={{ width: "100%" }}>
              <MDInput
                type='text'
                label='Tag Line'
                error={errorFinder(errors?.basicdetails?.tagline)}
                {...register("basicdetails.tagline", {})}
              />
              {errors?.basicdetails?.tagline && (
                <FormHelperText error id='basicdetails.tagline-helper-text'>
                  {errors?.basicdetails?.tagline?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              onBlur={() => {
                if (pageurl !== lastCheckedPageUrl) checkIfPageExist()
              }}
              variant='outlined'
              sx={{ width: "100%" }}
            >
              <MDInput
                // InputLabelProps={{ shrink: focusedPageUrl || pageurl !== "" }}
                label={"Page Url *"}
                error={errorFinder(errors?.basicdetails?.pageurl)}
                {...register("basicdetails.pageurl", {})}
                disabled={editablePage}
              />
              {errors?.basicdetails?.pageurl && (
                <FormHelperText error id='basicdetails.pageurl-helper-text'>
                  {errors?.basicdetails?.pageurl?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant='outlined' sx={{ width: "100%" }}>
              <MDInput
                label={"WhatsApp No. *"}
                error={errorFinder(errors?.basicdetails?.whatsappno)}
                {...register("basicdetails.whatsappno", {})}
              />
              {errors?.basicdetails?.whatsappno && (
                <FormHelperText error id='basicdetails.whatsappno-helper-text'>
                  {errors?.basicdetails?.whatsappno?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {url && (
            <>
              {/* //* Image Upload */}
              {!(imgloader.logoimg || logoImgPresent) && (
                <Grid item xs={12}>
                  <FormControl variant='outlined' sx={{ width: "100%" }}>
                    Logo Image{" "}
                    <input
                      className='border rounded-none text-base'
                      type='file'
                      // label="Logo Image *"
                      onChange={(e) => {
                        imageUpload(e.target.files[0], "logoimg")
                      }}
                      name='logoimg'
                      id='logoimg'
                    />
                  </FormControl>
                </Grid>
              )}

              <ImgLoader load={imgloader.logoimg} />

              {/* // * Logo Image Preview */}
              {logoImgPresent && (
                <Grid item>
                  Logo Image
                  <div className='relative'>
                    <DeleteIcon
                      sx={deleteIconStyles}
                      onClick={() => {
                        setValue("basicdetails.logoimg", "")
                      }}
                    />
                    <ImageViewer>
                      <img src={logoimg} alt={"logoImgPrev"} className='w-52 object-cover' />
                    </ImageViewer>
                  </div>
                </Grid>
              )}

              {!(imgloader.coverimg || coverImgPresent) && (
                <Grid item xs={12}>
                  <FormControl variant='outlined' sx={{ width: "100%" }}>
                    Cover Image{" "}
                    <input
                      className='border rounded-none text-base'
                      type='file'
                      // label="Cover Image *"
                      name='coverimg'
                      onChange={(e) => {
                        imageUpload(e.target.files[0], "coverimg")
                      }}
                      id='coverimg'
                    />
                  </FormControl>
                </Grid>
              )}

              <ImgLoader load={imgloader.coverimg} />

              {/* // * Cover Image Preview */}
              {coverImgPresent && (
                <Grid item>
                  Cover Image
                  <div className='relative'>
                    <DeleteIcon
                      sx={deleteIconStyles}
                      onClick={() => {
                        setValue("basicdetails.coverimg", "")
                      }}
                    />
                    <ImageViewer>
                      <img src={coverimg} alt={"coverImgPrev"} className='w-52 object-cover' />
                    </ImageViewer>
                  </div>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </PageSectionWrap>

      {/* Review Details */}
      {config.review && url ? (
        <PageSectionWrap
          saveButton
          header={
            <>
              <Typography fontWeight={500} variant='h5'>
                Review Details
              </Typography>
            </>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput label={"Review Link"} {...register("reviewdetails.reviewlink", {})} />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput label={"Review Description"} {...register("reviewdetails.reviewdescription", {})} />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    color='primary'
                    checked={watch("reviewdetails.positivereview")}
                    onChange={(e) => {
                      setValue("reviewdetails.positivereview", e.target.checked)
                    }}
                  />
                }
                label='Positive Review'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    color='primary'
                    checked={watch("reviewdetails.enabled")}
                    onChange={(e) => {
                      setValue("reviewdetails.enabled", e.target.checked)
                    }}
                  />
                }
                label='Enable Review'
              />
            </Grid>
          </Grid>
        </PageSectionWrap>
      ) : null}

      {/* Theme Details */}
      {url && (
        <PageSectionWrap
          saveButton
          header={
            <>
              <Typography fontWeight={500} variant='h5'>
                Theme Details
              </Typography>
            </>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography fontWeight={500} variant='h6'>
                  Theme Id
                </Typography>
                <Controller
                  control={control}
                  name='themedetails.themeid'
                  id='themedetails.themeid'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      inputRef={field.ref}
                      options={themeIdOptions}
                      // eslint-disable-next-line eqeqeq
                      value={themeIdOptions.find((c) => c.value == field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                        setValue("themedetails.themeid", val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
                {errors?.themedetails?.themeid && (
                  <FormHelperText error id='themeid-helper-text'>
                    {errors?.themedetails?.themeid?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput label='Color 1' type='color' {...register("themedetails.color1", {})} />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput label='Color 2' type='color' {...register("themedetails.color2", {})} />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <MDInput label='Background Color' type='color' {...register("themedetails.background.color", {})} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                Background Gradient Picker
                <>
                  {gradient ? (
                    <div
                      onClick={() => {
                        setShowColorPicker(true)
                      }}
                      style={{
                        cursor: "pointer",
                        background: gradient,
                        height: "40px",
                        width: "100%",
                        borderRadius: "8px",
                      }}
                    ></div>
                  ) : null}
                </>
                <div className='flex gap-4 mt-3'>
                  <MDButton
                    sx={{
                      maxWidth: "280px",
                      width: "100%",
                    }}
                    color='primary'
                    variant='gradient'
                    onClick={() => setShowColorPicker(true)}
                  >
                    Pick Gradient
                  </MDButton>

                  {gradient && (
                    <IconButton
                      onClick={() => setValue("themedetails.background.gradient", "")}
                      sx={{
                        color: "red",
                        backgroundColor: "#fCCC",
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "rgba(255, 51, 51, 0.3)",
                          color: "white",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                <Modal
                  header={
                    <Typography fontWeight={500} variant='h5'>
                      Color Picker
                    </Typography>
                  }
                  open={showColorPicker}
                  closeButton={false}
                  size='400px'
                >
                  <ColorPickerComponent
                    gradient={gradient}
                    setValue={setValue}
                    setShowColorPicker={setShowColorPicker}
                  />
                </Modal>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} variant='h6'>
                Font 1
              </Typography>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <Controller
                  control={control}
                  name='themedetails.font1'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      placeholder='Font 1'
                      inputRef={field.ref}
                      options={fontOptions}
                      value={fontOptions.find((c) => c.font === field.value)}
                      onChange={(val) => {
                        field.onChange(val.font)
                      }}
                      styles={customSelectStyles}
                      formatOptionLabel={formatOptionLabel}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} variant='h6'>
                Font 2
              </Typography>
              <FormControl variant='outlined' sx={{ width: "100%" }}>
                <Controller
                  control={control}
                  name='themedetails.font2'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      placeholder='Font 2'
                      inputRef={field.ref}
                      options={fontOptions}
                      value={fontOptions.find((c) => c.font === field.value)}
                      onChange={(val) => {
                        field.onChange(val.font)
                      }}
                      styles={customSelectStyles}
                      formatOptionLabel={formatOptionLabel}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {!(imgloader.backgroundimg || backgroundImgPresent) && (
              <Grid item xs={12}>
                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  Background Image{" "}
                  <input
                    className='border rounded-none text-base'
                    type='file'
                    // label="Background Image *"
                    name='backgroundimg'
                    onChange={(e) => {
                      imageUpload(e.target.files[0], "backgroundimg")
                    }}
                    id='backgroundimg'
                  />
                </FormControl>
              </Grid>
            )}

            <ImgLoader load={imgloader.backgroundimg} />

            {/* // * Background Image Preview */}
            {backgroundImgPresent && (
              <Grid item xs={12}>
                Background Image
                <div className='flex'>
                  <div className='relative'>
                    <DeleteIcon
                      sx={deleteIconStyles}
                      onClick={() => {
                        setValue("themedetails.background.image", "")
                      }}
                    />
                    <ImageViewer>
                      <img className='w-52 object-cover' src={backgroundimg} alt={"backgroundImgPrev"} />
                    </ImageViewer>
                  </div>
                </div>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography fontWeight={500} variant='h6'>
                  Background Size
                </Typography>
                <Controller
                  control={control}
                  name='themedetails.background.bgsize'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      placeholder='Select ...'
                      inputRef={field.ref}
                      options={objectFitOptions}
                      value={objectFitOptions.find((c) => c.value === field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* bgattachment */}
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' sx={{ width: "100%", maxWidth: "280px" }}>
                <Typography fontWeight={500} variant='h6'>
                  Background Attachment
                </Typography>
                <Controller
                  control={control}
                  name='themedetails.background.bgattachment'
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      placeholder='Select ...'
                      inputRef={field.ref}
                      options={bgAttachmentOptions}
                      value={bgAttachmentOptions.find((c) => c.value === field.value)}
                      onChange={(val) => {
                        field.onChange(val.value)
                      }}
                      styles={customSelectStyles}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </PageSectionWrap>
      )}
    </>
  )
}

export default BasicDetailsSection

const ColorPickerComponent = ({ gradient, setValue, setShowColorPicker }) => {
  let startingGradinet = useRef(null)

  useEffect(() => {
    startingGradinet.current = gradient
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex justify-center items-center flex-col'>
      <ColorPicker
        hideInputs
        height={100}
        // width={280}
        // hidePresets
        value={gradient}
        onChange={(val) => {
          setValue("themedetails.background.gradient", val)
        }}
      />

      <div className='flex gap-3 items-center justify-center mt-4'>
        <MDButton
          color='error'
          variant='gradient'
          onClick={() => {
            setShowColorPicker(false)
            setValue("themedetails.background.gradient", startingGradinet.current)
          }}
        >
          Close
        </MDButton>

        <MDButton
          color='primary'
          variant='gradient'
          onClick={() => {
            setShowColorPicker(false)
          }}
        >
          Pick
        </MDButton>
      </div>
    </div>
  )
}
