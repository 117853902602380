import React from "react"
import PageSectionWrap from "../PageSectionWrap"
import { Divider, FormControl, FormControlLabel, Grid, IconButton, Switch, Typography } from "@mui/material"
import { useFieldArray } from "react-hook-form"
import { setAlertMessage } from "redux/globalState"
import { useDispatch } from "react-redux"
import MDInput from "components/MDInput"
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"
import MDButton from "components/MDButton"

const VideoSection = ({ control, register, config, watch, setValue }) => {
  const dispatch = useDispatch()

  const {
    append: appendVidoes,
    fields: vidoesFields,
    remove: removeVideos,
  } = useFieldArray({
    control: control,
    name: "videos.data",
    keyName: "id",
    shouldUnregister: false,
  })

  const handleRemoveVideo = (index) => {
    if (vidoesFields.length === 1) {
      dispatch(setAlertMessage({ type: "error", message: "Atleast one Vidoe is required" }))
      return
    }
    removeVideos(index)
  }

  const handleAddProduct = () => {
    if (vidoesFields.length >= config.videos) {
      dispatch(setAlertMessage({ type: "error", message: `Maximum ${config.videos} Videos are only allowed` }))
      return
    }
    appendVidoes({ url: "", description: "" })
  }

  return (
    <PageSectionWrap
      initialOpen={false}
      saveButton
      header={
        <>
          <Typography fontWeight={500} variant='h5'>
            Videos
          </Typography>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            paddingTop: "12px !important",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={watch("videos.enabled")}
                onChange={(e) => {
                  setValue("videos.enabled", e.target.checked)
                }}
              />
            }
            label='Enable'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' sx={{ width: "100%" }}>
            <MDInput type='text' label='Video Section Title' {...register("videos.title", {})} />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "0px !important",
          }}
        >
          <Divider
            sx={{
              my: "1rem",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {vidoesFields.map((item, index) => (
            <>
              {index > 0 && (
                <Divider
                  sx={{
                    my: "1rem",
                  }}
                />
              )}
              {index > 0 && (
                <div className='text-end'>
                  <IconButton type='button' color='error' onClick={() => handleRemoveVideo(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              )}
              <div key={item.id} className='flex flex-col gap-4'>
                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  <MDInput type='text' label='Video Url' {...register(`videos.data.${index}.url`, {})} />
                </FormControl>
                <FormControl variant='outlined' sx={{ width: "100%" }}>
                  <MDInput
                    multiline={true}
                    rows={3}
                    type='text'
                    label='Video Description'
                    {...register(`videos.data.${index}.description`, {})}
                  />
                </FormControl>
              </div>
            </>
          ))}

          <MDButton
            sx={{
              gap: "0.5rem",
              marginTop: "12px",
            }}
            color='success'
            type='button'
            size='small'
            onClick={handleAddProduct}
          >
            <AddCircleOutline /> Add Video
          </MDButton>
        </Grid>
      </Grid>
    </PageSectionWrap>
  )
}

export default VideoSection
