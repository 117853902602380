import { useState, useEffect, useMemo } from "react"

//Tailwind css classes
import "main.css"

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom"

// @mui material components
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Icon from "@mui/material/Icon"

// Material Dashboard 2 React components
import MDBox from "components/MDBox"

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav"
import Configurator from "examples/Configurator"

// Material Dashboard 2 React themes
import theme from "assets/theme"

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark"

// Material Dashboard 2 React routes
import unProtectedRoutes from "unProtectedRoutes"
import protecedRoutes from "protectedRoutes"

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context"

// Images
import brandWhite from "assets/images/logo-ct.png"
import brandDark from "assets/images/logo-ct-dark.png"
import ProtectedRouteAuth from "auth/ProtectedRouteAuth"
import { useDispatch, useSelector } from "react-redux"
import Dashboard from "layouts/dashboard"
import { checkIfValid } from "utilities/commonFunctions"
import ViewPage from "layouts/pages/viewPage"
import NewPage from "layouts/newPage"
import Cart from "layouts/Cart/Cart"
import { useFetch } from "hooks/useFetch"

export default function App() {
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const { pathname } = useLocation()

  const dispatchR = useDispatch()

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const accessToken = useSelector((state) => state.globalState.accessToken)
  const username = useSelector((state) => state.globalState.username)
  const isValid = checkIfValid(accessToken, username)
  const userRole = useSelector((state) => state.globalState.userRole)

  const onCartSuccess = (response) => {
    dispatchR({ type: "globalState/setPurchase", payload: response })
  }

  const onCartError = (error) => {
    console.log("Cart Error", error)
  }

  const { data: purchase } = useFetch("cart", "/payment/get-cart-products.php", onCartSuccess, onCartError, isValid)

  const isPurchasedProduct1AndNotUsed = purchase
    ? purchase.paymenthistory?.some((item) => item.productid === "1" && item.isused === 0)
    : false

  const isSuperAdmin = userRole === "super_admin" ? true : false

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && isValid && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName='BizGo.in'
            routes={protecedRoutes.filter((route) => isSuperAdmin || route.key !== "products")}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}

      <Routes>
        {unProtectedRoutes.map((route) => {
          if (route.route && !isValid) {
            return <Route path={route.route} key={route.key} element={route.component} />
          }
          return null
        })}

        <Route element={<ProtectedRouteAuth />}>
          <Route path='/pages/:url' element={<ViewPage />} />

          <Route path='/new-page/:url' element={<NewPage />} />

          {isSuperAdmin && <Route path='/cart' element={<Cart />} />}

          {(isSuperAdmin || isPurchasedProduct1AndNotUsed || true) && <Route path='/new-page' element={<NewPage />} />}

          {protecedRoutes
            .filter((route) => route.access.includes(userRole))
            .map((route) => {
              if (route.route) {
                return <Route path={route.route} key={route.key} element={route.component} />
              }
              return null
            })}

          <Route path='*' element={<Dashboard />} />
        </Route>
      </Routes>
    </ThemeProvider>
  )
}
